import React,{useState, useEffect} from 'react'
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import IconSave from '@mui/icons-material/Save';
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Swal from 'sweetalert2';
import Select from 'react-select';

import CentroDeCostoAutorizadores from 'Repository/CentroDeCostoAutorizadores';

const initialValueSelect = {
    value: '',
    label: 'SELECCIONE UNA OPCIÓN'
}

export const AddAutorizadosCdcs = ({cdcs_uuid, onAdd, refresh}) => {

    const [autorizadoresList, setAutorizadoresList] = useState([]);
    const [autorizadorUuid, setAutorizadorUuid] = useState('');
    const [ selectClean, setSelectClean ] = useState( initialValueSelect );
    const cdcsAutorizadoresx = new CentroDeCostoAutorizadores();


    const listadocmb = [];

    //#region 
        useEffect(() => {
            autorizadoresDisponibles(cdcs_uuid);
        }, [])

        useEffect(()=>{
            
            if(refresh != undefined){
                if(refresh > 0){
                    autorizadoresDisponibles(cdcs_uuid);
                }
            }
        },[refresh])

        const handleSave = (event)=>{

            if(autorizadorUuid.length == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Seleccione un autorizador para poder continuar',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }
            
            let params ={
                cdcs_uuid, 'autorizador_uuid': autorizadorUuid
            }

            store(params);
           
        }

        const onChangeAutorizadorUuid = (event)=>{
            setAutorizadorUuid(event.value);
            setSelectClean(event);
        }
        
    //#endregion

    //#region  methods
        const autorizadoresDisponibles = async (cdcs_uuid)=>{
            const result = await cdcsAutorizadoresx.autorizadoresPorAsignar(cdcs_uuid);
            if(result.status = 'ok'){
                const itemsSelect = result.response.data.map(element=>{
                    return {
                        value: element.uuid,
                        label: element.nombre.toUpperCase()
                    }
                })
                setAutorizadoresList( [
                    {
                        value:'',
                        label:'SELECCIONE UNA OPCIÓN'
                    },
                    ...itemsSelect]);

            }
        }

        const store = (data)=>{
            cdcsAutorizadoresx.store(data).then(response=>{
                if(response.status == 'ok'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Excelente',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    autorizadoresDisponibles(cdcs_uuid);
                    setAutorizadorUuid('');
                    setSelectClean(initialValueSelect);
                    onAdd();
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Nodificaciones',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
            });
        }
    //#endregion

  return (
    <>
        <SoftBox pt={2} pb={3} px={3} component="form" role="form">
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={12}>
                    <SoftBox mb={1} lineHeight={0}>
                        <SoftTypography variant="caption" color="text">
                            Agregar autorizador
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox>

                        <Grid container >
                            <Grid item xs={8} md={8} xl={8}>

                                    <Select onChange={ onChangeAutorizadorUuid }
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name={"autorizador"}
                                        options={ autorizadoresList}
                                        value={ selectClean }
                                        menuPosition={'fixed'}
                                        styles={{
                                               container: (base) => ({
                                                   ...base,
                                                   fontSize:"14px",
                                                   fontFamily:"Arial"
                                               }),
                                               option: (base) => ({
                                                   ...base,
                                                   fontSize:"12px",
                                                   fontFamily:"Arial"
                                               })
                                           }}
                                    />


                            </Grid>
                            <Grid item xs={6} md={4} xl={4} pl={2}>
                                <SoftBox >
                                    <SoftButton fullWidth  color="color5V"  onClick={ handleSave } >
                                        <IconSave /> Agregar
                                    </SoftButton>
                                </SoftBox>
                            </Grid>
                        </Grid>
                        
                    </SoftBox>
                </Grid>
            </Grid>

        </SoftBox>
    </>
  )
}


AddAutorizadosCdcs.propTypes = {
    cdcs_uuid: PropTypes.string,
    onAdd: PropTypes.func,
    refresh: PropTypes.any
};

export default AddAutorizadosCdcs;
