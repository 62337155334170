
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';

export default axios.create({
    baseURL: `http://localhost/`
});

