import api_core from '../services/config'; 
import Operacion from './Operacion';

class Empresas extends Operacion {

    constructor() {
        super();
    }

    async listar(uuid){
        const url = `panel/empresa/${uuid}/listar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }
    
    async store(data){
        let url = `panel/empresa/registrar`;
        const response = await  this.transaction('POST',url,data , true );
        return response;
    }

    static cambiarEstado(item, value, password){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE}panel/empresa/${item.uuid}/estatus/${value}`;
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({password}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }

    async mostrar(uuid){
        const url = `panel/empresa/${uuid}/mostrar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async update(data, uuid){
        const url = `panel/empresainfo/${uuid}/actualizar`;
        const response = await  this.transaction('POST',url,data , true );
        return response;
    }


}

export default Empresas;