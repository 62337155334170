import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IconSave from '@mui/icons-material/Save';
import Grid from "@mui/material/Grid";

import Swal from 'sweetalert2';
import Personas from 'Repository/Personas';

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function ResetPassword() {

    let [passowrdAnterior, setPasswordAnterior] = useState( '' );
    let [passowrdNuevo, setPasswordNuevo] = useState( '' );
    let [passowrdConfirmado, setPasswordConfirmado] = useState( '' );
    
    const handleCheckChange = ({target})=>{
        setState( target.checked ) ;
        cambiarEstado(item, target.checked,  objeto.cambiarEstado);
        Swal.fire({
            title: 'Notificaciones',
            text: 'Se ha cambiado el estatus del registro.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        });
    }

    //#region events
    const handleChangePassword = (event)=>{
        save();
    }
    //#endregion

    //#region methods
        const save = async (event)=>{

            const validate = await formValidate();
            if(validate.status == 'ok'){
                const params = {
                    'new_password': passowrdConfirmado,
                    'old_password': passowrdAnterior
                }
                const response = await Personas.resetPassword(params);
                if(response.status == 'ok'){
                    setPasswordAnterior('');
                    setPasswordNuevo('');
                    setPasswordConfirmado('');
                    Swal.fire({
                        icon: 'success',
                        title: 'Notificaciones',
                        html: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        html: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
            }
        }

        const formValidate = ()=>{
            return new Promise((resolve, fallback)=>{
                if( passowrdAnterior.trim() == ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        html: 'Ingrese su contraseña actual',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    resolve({status:'error'});
                }
                if( passowrdNuevo.trim() == ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        html: 'Ingrese su nueva contraseña',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    resolve({status:'error'});
                }
                if( passowrdConfirmado.trim() == ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        html: 'Confirme su contraseña',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    resolve({status:'error'});
                }
                if( passowrdConfirmado.trim() !=  passowrdNuevo.trim() ){
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        html: 'Las contraseñas no coinciden',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    resolve({status:'error'});
                }
                resolve({status:'ok'});
            })
            
            
        }
    //#region end

    return(
        <>
            <Grid container>
                <Grid item xs={12} md={12} xl={12}>
                    <SoftBox mb={1} lineHeight={0}>
                        <SoftTypography variant="caption" color="text">
                            Cambiar contraseña
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox pt={1}> 
                        <SoftInput placeholder="Contrase anterior" 
                            maxLength="10"  type="password" 
                            value={passowrdAnterior} onChange={ e => setPasswordAnterior(e.target.value) } autoComplete="current-password"
                        />
                    </SoftBox>
                    <SoftBox pt={1}> 
                        <SoftInput placeholder="Contrase nueva" 
                            maxLength="10"  type="password"
                            value={passowrdNuevo} onChange={ e => setPasswordNuevo(e.target.value) } autoComplete="new-password"
                        />
                    </SoftBox>
                    <SoftBox pt={1}> 
                        <SoftInput placeholder="Confirmar contraseña" 
                            maxLength="10" type="password"
                            value={passowrdConfirmado} onChange={ e => setPasswordConfirmado(e.target.value) } autoComplete="new-password"
                        />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={12} xl={12} pt={1}>
                    <SoftBox >
                        <SoftButton fullWidth variant="gradient"  color="dark"  onClick={ handleChangePassword } >
                            <IconSave /> Cambiar contraseña
                        </SoftButton>
                    </SoftBox>
                </Grid>


           </Grid>
        </>
    )
    
}


export default ResetPassword;