/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SignIn from "layouts/authentication/sign-in";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// @mui material components
import Grid from "@mui/material/Grid";

import Swal from 'sweetalert2';

import PersonasRequest from '../../Repository/Personas.js';

import Table from "examples/Tables/Table"; 
import IconRefresh from '@mui/icons-material/Refresh';
import SoftBadge from "components/SoftBadge/index.js";

import Estatus from "layouts/personas/components/Estatus";
import Profile from "../personas/components/Profile";
import CuentasRelacionadas from '../personas/components/CuentasRelacionadas';
import { Loading } from "examples/Components/Loading.jsx";
import {useDispatch, useSelector} from "react-redux";
import {consultaTiposNotificaciones, editPersona} from "../../store/thunk/persona";
import Select from 'react-select';
import LinearProgress from "@mui/material/LinearProgress";
import {useAlert} from "../../customHooks/useAlert";

const initialItemSelect = {
  value: '',
  label: 'SIN NOTIFICACIÓN'
}

function Personas() {

  const dispatch = useDispatch();
  const {currentRecord : viewPersona, uuid: uuidShowed, tipoNotificaciones: catTipoNotificaciones } = useSelector(state => state.persona);

  const [errores, setErrores] = useState('');
  const { showAlert } = useAlert();

  /* Estado de autorizador */
  const [nombres_autorizador, setNombresAutorizador] = useState('');
  const [apellidos_autorizador, setApellidosAutorizador] = useState('');
  const [celular_autorizador, setCelularAutorizador] = useState('');
  const [email_autorizador, setEmailAutorizador] = useState('');
  const [referencia_autorizador, setReferenciaAutorizador] = useState('');
  const [ tipoNotificacion_uuid, setTipoNotificacionUuid ] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [periodo, setPeriodo] = useState([]);
  const [selectedItemNotificacion, setSelectedItemNotificacion ] = useState( initialItemSelect );
  /* Estado de autorizador */
  const [txtBuscar,setTxtBuscar ] = useState('');

  const [loading, setLoading] = useState(false);

  /* Estado del detalle */
  //const [uuidShowed,setIuuidShowed ] = useState('');
  /* Estado del detalle  */

  let personax = new PersonasRequest();

  useEffect(()=>{
    dispatch( consultaTiposNotificaciones() );
  }, [])

/* validacion en tiempo real de lo que se captura en los campos, marcar en rojo o verde segun lo que se esta ingresando */
  
  /* Nombres de los autorizadorees start   */
  function getErrNombresAutorizador(negative){
    if(negative){
      if(nombres_autorizador.length < 3) return true;
      return false;
    }else{
      if(nombres_autorizador.length > 3 ) return true;
      return false;
    }
  }

  function getErrApellidosAutorizador(negative){
    if(negative){
      if(apellidos_autorizador.length < 3) return true;
      return false;
    }else{
      if(apellidos_autorizador.length > 3 ) return true;
      return false;
    }
  }

  function getErrReferenciaAutorizador(negative){
    if(negative){
      if(referencia_autorizador.length < 3) return true;
      return false;
    }else{
      if(referencia_autorizador.length > 3 ) return true;
      return false;
    }
  }
  
  function getErrCelularAutorizador(negative){
    if(negative){
      let restmpx = celular_autorizador.match(/^\d{10}$/g)
      if(restmpx == null) return true;
      return false;

    }else{
      let restmpx = celular_autorizador.match(/^\d{10}$/g)
      if(restmpx) return true;
      return false;
    }
  }

  function getErrEmailAutorizador(negative){
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if(negative){
      
      if(!emailRegex.test(email_autorizador)) return true;
      return false;

    }else{
      
      if(emailRegex.test(email_autorizador)) return true;
      return false;
    }
  }
  /* Nombres de los autorizadorees end   */


  const sendRegisterAutorizador = async  e=>{
    e.preventDefault();
    
    if(!validateAutorizador()){
      setLoading(true);
      let params = {
        nombres   : nombres_autorizador,
        apellidos : apellidos_autorizador,
        celular   : celular_autorizador,
        email     : email_autorizador,
        referencia: referencia_autorizador,
        nt_uuid   :tipoNotificacion_uuid,
        periodo: periodo
      }
      PersonasRequest.registrar(params).then(response=>{
        //limpiamos componentes
        if(response.status == 'ok'){
          setNombresAutorizador(()=> '');
          setApellidosAutorizador(()=> '');
          setCelularAutorizador(()=> '');
          setEmailAutorizador(()=> '');
          setReferenciaAutorizador( ()=> '' );
          setSelectedItemNotificacion(initialItemSelect);
          setTipoNotificacionUuid('');
          setPeriodo('');
          listar();
          Swal.fire({
            title: 'Notificaciones',
            text: response.response.text,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
          });
          setErrores(()=> '');
        }else{
          setErrores(()=> response.response.error );
        }
        setLoading(false);
      });

    }else{
      setErrores('Por favor rellene los campos necesarios para poder continuar');
    }
  }

  function handleShowClick(event, uuid){
    event.preventDefault();
    //setIuuidShowed(uuid);
    dispatch( editPersona(uuid) );
  }

  const onCancelHandle = ()=>{
    //setIuuidShowed('');
    listar('');
  }

  const handleChangeBusqueda = ({target})=>{
    const value = target.value;
    setTxtBuscar(value);
    if(value.length > 3){
      listar(value);
    }else if(value == ''){
      setLoading(true);
      listar('');
    }
    
  }

  const handleRefresh = ()=> listar();

  function listar(texto = ''){
    
    let params = {nombres:texto, soloActivos: false};
    personax.listar(params).then(response=>{
      
      setColumns(
      [
        { name: "Nombre", align: "left" },
        { name: "Correo", align: "center" },
        { name: "Celular", align: "center" },
        { name: "Empresa", align: "center" },
        { name: "OTP", align: "center" },
        { name: "Activo", align: "center" },
      ]
      );
      let item = [];
      (response.response.data).forEach(element => {
        
        let row = { 
          Nombre:  <SoftTypography
                      component="a"
                      href="#"
                      variant="caption"
                      color="info"
                      fontWeight="medium" onClick={ (event) => handleShowClick(event,element.uuid)  }
                    >
                      {   element.nombres+" "+ element.apellidos}
                    </SoftTypography> ,
          Correo:element.email,
          Celular:element.celular,
          Empresa: <SoftTypography variant="caption">
                    { element.referencia ? element.referencia : "" }
                  </SoftTypography>,
          OTP: (
            <>
              {
                (element.otpSecret && element.otpLastUse) ? (
                  <SoftBadge variant={  (element.otpSecret && element.otpLastUse) ? "gradient": "contained"  } 
                  badgeContent={  (element.otpSecret && element.otpLastUse) ? " otp ": "  "  } size="lg" container />
                ) : ''
              }  
            </>
          ),
          Activo: ( <Estatus key={element.uuid} item={element} clase="persona"   />
                )
                 
        };
        item = [...item, row];
      });
      setRows(item);
      setLoading(false);
      
    });
  }

  function validateAutorizador(){
    let hasError = false;
    let counter = 0;
    if(nombres_autorizador.length < 3) {counter+=1; }
    if(apellidos_autorizador.length < 3){counter+=1; }
    if(referencia_autorizador.length < 3){counter+=1; }
 
    let restmpx = celular_autorizador.match(/^\d{10}$/g)
    if(restmpx == null) {counter+=1; }

    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if(!emailRegex.test(email_autorizador)) {counter+=1; }
    
    if(counter > 0){//XD no has capturado todo
      return (hasError = true);
    }

    let error = false;
    if(selectedItemNotificacion.label != 'SIN NOTIFICACIÓN'){
      error = (tipoNotificacion_uuid == '' || tipoNotificacion_uuid == undefined || tipoNotificacion_uuid == null) && (periodo == '' || periodo == undefined);
    }
    if( error) return true;
    if( selectedItemNotificacion.label != 'SIN NOTIFICACIÓN' && (periodo == undefined || periodo == '')) {
      showAlert('Ups', "Ingrese un periodo", "warning");
      return true;
    };
    const rangoInvalido = selectedItemNotificacion.label != 'SIN NOTIFICACIÓN' && ( parseInt(periodo) <= 0 || parseInt(periodo) > 23 );
    if( rangoInvalido ){
      showAlert('Ups', "Ingrese un periodo de 1 a 23 horas", "warning");
      return true;
    }

    return hasError;

  }


  useEffect(()=>{
    listar('');
  },[]);

  const onChangeTipoNotificacion = (event)=>{
    setTipoNotificacionUuid(event.value);
    setSelectedItemNotificacion( event );
  }

  //

  //Si ya esta autenticado devolvemos el dashboard si no lo mandamos a loguearse

    return (
      <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Loading loading={ loading } />
        
      { uuidShowed === null && (
          <Card  >
          <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography variant="h5" fontWeight="medium">
                Persona
              </SoftTypography>

              <SoftBox mb={1}>
                <SoftTypography variant="button" fontSize="xxs" color="error" >
                  { errores }
                </SoftTypography>
              </SoftBox>
              
            
            <SoftBox component="form" role="form" onSubmit={sendRegisterAutorizador}>

              <Grid container spacing={3} pt={2}>

                <Grid item xs={12} md={6} xl={4}>
                  <SoftBox >
                    <SoftInput type="text" placeholder="Nombres"   value={nombres_autorizador} name="nombres"
                    error={ getErrNombresAutorizador(true) } success={ getErrNombresAutorizador(false)}
                    onChange={ e => setNombresAutorizador(e.target.value) } 
                    maxLength={10} autoComplete="given-name"
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} md={3} xl={8}>
                  <SoftBox >
                    <SoftInput type="text" placeholder="Apellidos"   value={apellidos_autorizador} name="apellidos"
                    error={ getErrApellidosAutorizador(true) } success={ getErrApellidosAutorizador(false)}
                    onChange={ e => setApellidosAutorizador(e.target.value) } 
                    maxLength={10} autoComplete="additional-name"
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} md={4} xl={4}>
                  <SoftBox >
                    <SoftInput placeholder="Empresa" maxLength="10"
                    value = {referencia_autorizador} name="empresa"
                    error={ getErrReferenciaAutorizador(true) } success={ getErrReferenciaAutorizador(false)}
                    onChange={ e => setReferenciaAutorizador(e.target.value) } 
                    autoComplete="on"
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} md={4} xl={4}>
                  <SoftBox >
                    <SoftInput placeholder="Celular" maxLength="10"
                    value = {celular_autorizador} name="celular"
                    error={ getErrCelularAutorizador(true) } success={ getErrCelularAutorizador(false)}
                    onChange={ e => setCelularAutorizador(e.target.value) } 
                    autoComplete="on"
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} md={4} xl={4}>
                  <SoftBox >
                    <SoftInput placeholder="Correo" type="email"
                    value={email_autorizador} name="correo"
                    error={ getErrEmailAutorizador(true) } success={ getErrEmailAutorizador(false)}
                    onChange={ e => setEmailAutorizador(e.target.value) } 
                    autoComplete="email"
                    />
                  </SoftBox>
                </Grid>


                <Grid item xs={12} md={6} xl={4}>

                  <Select  onChange={ onChangeTipoNotificacion }
                           className="basic-single"
                           classNamePrefix="select"
                           isSearchable={true}
                           name={"tipoNotificacion"}
                           options={ catTipoNotificaciones }
                           value={ selectedItemNotificacion }
                           menuPosition={'fixed'}
                           styles={{
                             container: (base) => ({
                               ...base,
                               fontSize:"14px",
                               fontFamily:"Arial"
                             }),
                             option: (base) => ({
                               ...base,
                               fontSize:"12px",
                               fontFamily:"Arial"
                             })
                           }}
                  />

                </Grid>

                <Grid item xs={12} md={4} xl={4}>
                  <SoftBox >
                    <SoftInput placeholder="Periodo" type="number"
                               value={periodo}
                               onChange={ e => setPeriodo(e.target.value) }
                               autoComplete="periodo"
                    />
                  </SoftBox>
                </Grid>

              </Grid>

              <Grid item xs={12} md={12} xl={12} pt={2}>
                <SoftButton color="color6V" fullWidth type="submit"  >
                  Agregar Persona
                </SoftButton>
              </Grid>

            </SoftBox>

            
          </SoftBox>

          

          <SoftBox mb={3} pt={1}>
            <Card>
              
              <Grid container>
                <Grid item xs={4} md={3} xl={2}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SoftTypography variant="h6">Personas</SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item pl={1} xs={4} md={3} xl={2} pt={2}>
                    <SoftButton color="color5V" onClick={handleRefresh}  >
                        <IconRefresh fontSize="36px"  color="white" title="Actualizar" /> Refrescar
                    </SoftButton>
                </Grid>
                <Grid item pl={1} xs={1} md={4} xl={4}>
                </Grid>
                <Grid item xs={4} md={4} xl={4}>
                  <SoftBox mr={3}  pt={2}>
                    <SoftInput placeholder="Buscar" type="text"
                    value={txtBuscar}
                    onChange={ (event)=> handleChangeBusqueda(event) } 
                    />
                  </SoftBox>
                </Grid>

              </Grid>
              
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={columns} rows={rows} />
              </SoftBox>
            </Card>
          </SoftBox>

        </Card>
      )}
          
      
      { uuidShowed != null && (
        <>
          <Profile  onCancel={ (value)=>onCancelHandle(value) } />
          <CuentasRelacionadas  />
        </>
          
      )}
      

      </SoftBox>
    </DashboardLayout>
    );

}

export default Personas;
