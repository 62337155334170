
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import SoftBox from "../../../../components/SoftBox";
import {v4 as uuidv4} from "uuid";
import {TableCell} from "@mui/material";
import SoftAvatar from "../../../../components/SoftAvatar";
import SoftTypography from "../../../../components/SoftTypography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

function BodyRowComplete({ row, show  } ) {
    const { borderWidth } = borders;
    const { light } = colors;
    const [open, setOpen] = useState(false);

    return (<>
            <TableRow  >
                <TableCell key={uuidv4()} sx={{  width:420,paddingTop:0.5, paddingBottom:0.5 }} size={"small"} >
                    <Grid container>
                        <Grid item xs={11} md={11} xl={11}>
                                { row['Detalle2'] }
                        </Grid>
                        <Grid item xs={1} md={1} xl={1} textAlign={"right"}>
                            {
                                show &&
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            }
                        </Grid>
                    </Grid>

                </TableCell>
                <TableCell key={uuidv4()}  align={"right"} sx={{ width:150,paddingTop:0.5, paddingBottom:0.5 }} size={"small"}  >
                    { row['Retiros'] }
                </TableCell>
                <TableCell key={uuidv4()} align={"right"} sx={{ width:150,paddingTop:0.5, paddingBottom:0.5 }} size={"small"}  >
                    { row['Depositos'] }
                </TableCell>
                <TableCell key={uuidv4()}  align={"right"} sx={{ width:150,paddingTop:0.5, paddingBottom:0.5 }} size={"small"}  >
                    { row['Saldo'] }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell key={uuidv4()} colSpan={5} style={{ paddingTop:0.5, paddingBottom:0.5, backgroundColor:"#e9e9e9" }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        { row['Detalle'] }
                    </Collapse>
                </TableCell>
            </TableRow>
            </>
    );
}

// Typechecking props for the ListItem
BodyRowComplete.propTypes = {
    row: PropTypes.any.isRequired,
    show: PropTypes.bool.isRequired,
};

export default BodyRowComplete;