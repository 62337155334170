/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
//para redirigir

// Images
import brand from "assets/images/favicon-128.ico";
import Goolgle2FA from "layouts/authentication/google2fa";
import CuentaMaestra from "layouts/cuentamaestra";
import CentroItem from "layouts/centrosdecostos/Components/CentroItem";
import ResetPassword from "layouts/authentication/reset-password";
import ChangePassword from "layouts/authentication/change-password";
import {useCheckAuth} from "./customHooks/useCheckAuth";
import {AuthRoutes} from "./appRoutes/AuthRoutes";
import {PanelRoutes} from "./appRoutes/PanelRoutes";
import Loader from "./examples/Components/Loader";
import {NotificacionesSnack} from "./examples/Components/NotificacionesSnack";
import {useWindowHeight} from "./customHooks/useWindowHeight";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { logged, user } = useCheckAuth();
  const { windowHeigth } = useWindowHeight(window.innerHeight);

  if(logged == 'checking'){
    return <Loader />
  }

  // Cache for the rtl

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);




  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  const CustomTitle = (<label>Banca ALPHA ERP<sup>®</sup></label>);

  return  (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName= {CustomTitle}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          <NotificacionesSnack />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>

        

        {
          logged == 'logged'  ?
              <Route path="/*" element={ <PanelRoutes />  } />
            : <Route path="/*" element={ <AuthRoutes />  } />
        }

        <Route exact path="authentication/google2fa/:token" element={ <Goolgle2FA /> } key="two-factor"  />
        <Route exact path="authentication/setcredentials/:token" element={ <ResetPassword /> } key="reset-password"  />
        <Route exact path="authentication/renewcredentials/:token" element={ <ChangePassword /> } key="change-password"  />
        <Route exact path="cuentamaestra/:cuenta" element={ <CuentaMaestra /> } key="cuenta-master"  />
        <Route exact path="centro/:uuidParam" element={ <CentroItem /> } key="centro-master"  /> 
      

        
      </Routes>
    </ThemeProvider>
  );
}

