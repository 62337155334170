

import {forwardRef} from "react";
import SoftBox from "../../../../components/SoftBox";
import {Table, TableCell, TableContainer} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useNumberFormat} from "../../../../customHooks/useNumberFormat";


export const printComprobante = forwardRef((props, ref) => {
    const datax = props;
    const element = datax.props;
    const { numberFormat } = useNumberFormat();
    return (
        <SoftBox component={"div"} ref={ref}>

            <SoftBox sx={{ alignContent: "center", paddingLeft:10, paddingRight:10, paddingTop:5 }}>
                <TableContainer>
                    <Table sx={{ maxWidth: 650 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={ 2 } sx={{ backgroundColor:"#3E3E3E", textAlign:"center" }}>
                                    <img src={ "https://www.castelec.mx/images/Banca-ALPHAERP.png" }
                                        width={250} height={70}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ backgroundColor:"#5A5A5A" ,textAlign: "center",fontSize:"13px",color:"#ffffff",paddingtop:"4px",paddingBottom:"4px",paddingLeft:"12px" }} >
                                    Recibo de la transferencia
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Cuenta Origen
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.ordenante } - { element.cuentaOrdenante }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    RFC /CURP Origen
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.rfcCurpOrigen }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Banco Origen
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.banco_ordenante }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Cuenta Destino
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.beneficiario } - { element.cuentaBenenficiario }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    RFC / CURP Destino
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.rfcCurpDestino }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Banco Destino
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.banco_beneficiario }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Concepto  Pago
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.conceptoPago }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Monto
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { numberFormat(element.monto,2) }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Referencia Numérica
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.referenciaNumerica }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Clave de Rastreo
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.claveRastreo }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize:"12px",fontWeight:"bold",paddingBottom:"4px",paddingTop:"4px",paddingLeft:"12px",borderBottom:"1px solid #999999" }}>
                                    Fecha de Liquidación
                                </TableCell>
                                <TableCell sx={{ fontSize:"12px",backgroundColor:"#f0f0f0", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    { element.fecha_hora }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ fontSize:"10px", borderBottom:"2px solid #ffffff",paddingLeft:"12px" }}>
                                    ¿Tienes dudas?, llámanos al Centro de Atención a Clientes en Monterrey al 81 8151 3535; Nuestros otros <a href="https://www.castelec.mx/Nosotros.html#sucursales">Centros de Atención a Clientes</a>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>



            </SoftBox>

        </SoftBox>
    );
});

export  default  printComprobante;