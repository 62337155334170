
class Plaza{

    static cambiarEstado(plaza, value){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE}panel/plaza/${plaza.plaza_uuid}/${plaza.cdcs_uuid}/estatus/${value}`;
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };
            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) )
                ;
        });
    }

}

export default Plaza;
