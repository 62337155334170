import api_core from '../services/config';
import Operacion from './Operacion';

class Cuentas extends Operacion {

    constructor() {
        super();
    }

    //Crea una persona, sin asignarle ningun tipo de permiso
    async listar(data){
        const url = `panel/cuentas/listar?txtbuscar=${data.txtbuscar}`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async mostrar(uuid){
        const url = `panel/cuenta/${uuid}/mostrar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async mostrarDetalle(uuid){
        const url = `panel/cuenta/${uuid}/detalle`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async update(data, uuid){
        const url = `panel/cuenta/${uuid}/actualizar`;
        const response = await  this.transaction('PUT',url, data , true );
        return response;
    }

    async delete(uuid,value, passtext){
        let url = `panel/cuenta/${uuid}/estatus/${value}`;
        const response = await this.transaction('PUT',url, JSON.stringify({passtext: passtext}) , true );
        return response;
    }

    async Cdc(cuenta_uuid){
        const url = `panel/cuenta/cdcs?cuenta_uuid=${cuenta_uuid}`;
        const response = await this.transaction('GET',url, null , true );
        return response;
    }


}

export default Cuentas;




