import React, {   useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Card from "@mui/material/Card";

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
import IconAdd from '@mui/icons-material/Add';

import SoftInput from "components/SoftInput";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2';
//
import LicenciasRequest from '../../../Repository/Licencias';

function AddLicencia({uuid,data,  onAdd, onCancel, reset }) {

    const [errores, setErrores] = useState('');
    const [Serie, setSerie] = useState('');
    const [toEdit, setToEdit] = useState('');
    const licenciax = new LicenciasRequest();
    //#region methods
    const saveData= (  )=>{

        setErrores('');
        if(!validate()){
            const params = {
                'serie' : Serie,
                'cuenta_uuid' : uuid,
                'uuid': toEdit
            }

            if(toEdit == '' || toEdit == undefined ){
                licenciax.store( JSON.stringify(params) ).then(response=>{
                    if(response.status == 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                        onAdd();
                        setSerie('');
                       
                    }else{
                      setErrores(response.response.text);
                    }
                });
            }else{
                licenciax.update( JSON.stringify(params) , toEdit).then(response=>{
                    if(response.status == 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                        onAdd();
                    }else{
                      setErrores(response.response.text);
                    }
                });
            }

            
        }else{
            setErrores('Por favor rellene los campos necesarios para poder continuar');
        }    
    }

    
    useEffect(()=>{
        setSerie(data.serie);
        setToEdit(data.uuid);
    },[data])

    useEffect(()=>{
        if(reset > 0){
            setSerie('');
            setToEdit('');
        }
    }, [reset] );


    function validate (){
        let hasError = false;
        let counter = 0;
        if( Serie.length == 0) { counter +=1; }
        if( Serie.match(/^[0-9]+$/g) == null) { counter +=1; }

        if(counter > 0){//XD no has capturado todo
        return (hasError = true);
        }
    
        return hasError;
    }

    //#endregion

    //#region  events

    const onClickAddLicencia = (event)=>{
        saveData();
    }

    const onClickCancelEmpresa = (event)=>{
        onCancel();
    }

    const onClickNuevoEmpresa = ()=>{
        setSerie('');
        setToEdit('');
    }

    //#endregion

    return(
        <>
            <SoftBox>
                <hr/>
                    <Grid container >
                        <Grid item xs={8} md={8} xl={12} pt={2}>
                            <SoftBox pl={5} display="flex" alignItems="center" lineHeight={0}>
                                <SoftTypography variant="button" fontSize="xxs" color="error" >
                                    { errores }
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <SoftBox  px={3} component="form" role="form" pb={2}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={4} xl={3}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Licencia
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox> 
                                    <SoftInput placeholder="Ej. 235" 
                                        maxLength="10" 
                                        value={ Serie } onChange={ e => setSerie(e.target.value) } autoComplete="off"
                                        
                                    />
                                </SoftBox>
                            </Grid>
                            
                            {/*
                                 <Grid item xs={12} md={4} xl={2}>
                                    <SoftBox pt={3}>
                                        <SoftButton fullWidth variant="gradient"  color="warning" onClick={ (event) => onClickNuevoEmpresa(event) }  >
                                            <IconAdd /> Nuevo
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            */}
                           

                            <Grid item xs={12} md={4} xl={2}>
                                <SoftBox pt={3}>
                                    <SoftButton fullWidth color="color5V" onClick={ (event) => onClickAddLicencia(event) }  >
                                        <IconSave /> Guardar
                                    </SoftButton>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={4} xl={3}>
                                <SoftBox pt={3}>
                                    <SoftButton fullWidth  color="color8" onClick={ (event) => onClickCancelEmpresa(event) }  >
                                        <IconBack /> Cancelar captura
                                    </SoftButton>
                                </SoftBox>
                            </Grid>

                        </Grid>
                    </SoftBox>
                <hr/>
            </SoftBox>
        </>
    )
    
}

AddLicencia.defaultProps= {
    data:{
        'uuid' :'',
        'serie' : ''
    }
}

AddLicencia.propTypes = {
    uuid: PropTypes.string,
    onAdd : PropTypes.func,
    data: PropTypes.any,
    onCancel: PropTypes.func,
    reset : PropTypes.any
  };

export default AddLicencia;