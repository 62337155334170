
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import TableEstadoCuenta from "../../../../examples/Tables/TableEstadoCuenta";
import Grid from "@mui/material/Grid";
import LabelCellAny from "./LabelCellAny";
import DetalleCell from "./DetalleCell";
import Card from "@mui/material/Card";
import RowHeaders from "./RowHeaders";
import {useNumberFormat} from "../../../../customHooks/useNumberFormat";
import TotalOperaciones from "./TotalOperaciones";
import Total from "./Total";
import {SumarioOperaciones} from "./SumarioOperaciones";
import IconButton from "@mui/material/IconButton";
import CentroDeCosto from "../../../../Repository/CentroDeCosto";

import logonuevo from "assets/images/curved-images/Banca-SAIERP-690x300.png";
import SoftAvatar from "../../../../components/SoftAvatar";
import moment from "moment/moment";
import DetalleCell2 from "./DetalleCell2";
import {descargaArchivo, verEstadocuentaPersonalizado} from "../../../../store/thunk/estadocuenta";
import {isRefreshing} from "../../../../store/slice/estadoCuentaSlice";
import {useWindowHeight} from "../../../../customHooks/useWindowHeight";


const columns = [
    { name: "Fecha", align: "left", width:"20%" },
    { name: "Detalle", align: "left", width:"60%" },
    { name: "Detalle2", align: "left", width:"60%" },
    { name: "Retiros", align: "right" },
    { name: "Depositos", align: "right" },
    { name: "Saldo", align: "right" },
];
export const EstadoDeCuenta = () => {
    const dispatch = useDispatch();
    const { numberFormat } = useNumberFormat();
    const { windowHeight } = useSelector( state => state.ventana );
    const { cdc_uuid,        periodo,        transacciones,        filtro, downloading, refreshing }   = useSelector(state => state.estadocuenta);
    const [ transaccionesLiquidadas, setTransaccionesLiquidadas ]               = useState();
    const [ dataOperaciones, setDataOperaciones ]                               = useState();
    const [ transaccionesEnTransito, setTransaccionesEnTransito ]               = useState();
    const [ transaccionesEnTransitoTotal, setTransaccionesEnTransitoTotal ]     = useState();
    const [ total, setTotal ]                                                   = useState();
    const [ cantidadRetiros, setCantidadRetiros ]                               = useState();
    const [ cantidadDepositos, setCantidadDepositos ]                           = useState();
    const centrox = new CentroDeCosto();
    const format = "YYYY-MM-DD"
    //#region methods
    useEffect(()=>{
        load();
    },[transacciones]);

    const load = ()=>{

        let saldo               = transacciones.saldoPeriodoAnterior;
        let depositosTotal      = 0;
        let retirosTotal        = 0;
        let cantidadRetiros     = 0;
        let cantidadDepositos   = 0;
        const data = transacciones.data.map(element=>{
            let deposito    = 0;
            let retiro      = 0;
            if(element.tipo == "DEPOSITO"){
                deposito = parseFloat(element.monto);
                if(element.estado !== 'DEVUELTO')
                {
                    saldo   += parseFloat(element.monto) ;
                    depositosTotal += deposito;
                    cantidadDepositos += 1;
                }
            }else{
                retiro          = parseFloat(element.monto);
                saldo           -= parseFloat(element.monto) ;
                retirosTotal    += retiro;
                cantidadRetiros +=1;
            }

            const detalleComponent = <DetalleCell element={ element } />;
            const detalleComponent2 = <DetalleCell2 element={ element } />;

            return {
                "Detalle2"  :detalleComponent2,
                "Detalle"   :detalleComponent,
                "Retiros"   : <LabelCellAny label={ numberFormat(retiro)  } /> ,
                "Depositos" : <LabelCellAny label={ numberFormat( deposito ) } /> ,
                "Saldo"     : <LabelCellAny label={ numberFormat(saldo) } /> ,
            }
        });

        const datatotal =
            {
                "Detalle"   : "Total de Operaciones",
                "Retiros"   :  numberFormat(retirosTotal)  ,
                "Depositos" :  numberFormat(depositosTotal) ,
                "Saldo"     :  numberFormat(saldo) ,
            };

        setTransaccionesLiquidadas(data);
        setDataOperaciones( datatotal );

        let totalRetirosEnTransito = 0;
        const enTransito = transacciones.data_captura.map(element=>{

            const detalleComponent = <DetalleCell element={ element } />;
            const detalleComponent2 = <DetalleCell2 element={ element } />;

            let retiro      = 0;
            if(element.tipo == "DEPOSITO"){
            }else{
                retiro  = parseFloat(element.monto);
                saldo   -= parseFloat(element.monto) ;
                totalRetirosEnTransito += parseFloat(element.monto);
                retirosTotal += retiro;
                cantidadRetiros += 1;
            }

            return {
                "Detalle"   : detalleComponent,
                "Detalle2"   : detalleComponent2,
                "Retiros"   : <LabelCellAny label={ numberFormat(retiro)  } /> ,
                "Depositos" : <LabelCellAny label={ "" } /> ,
                "Saldo"     : <LabelCellAny label={ numberFormat(saldo) } /> ,
            }
        })

        setTransaccionesEnTransito(enTransito);
        setTransaccionesEnTransitoTotal({
            "Detalle"   : "Total de operaciones en tránsito",
            "Retiros"   : numberFormat(totalRetirosEnTransito) ,
            "Depositos" : "" ,
            "Saldo"     : numberFormat(saldo) ,
        });

        setTotal({
            "Detalle"   : "Total",
            "Retiros"   : numberFormat(retirosTotal) ,
            "Depositos" : "" ,
            "Saldo"     : numberFormat(saldo) ,
        })

        setCantidadRetiros(cantidadRetiros);
        setCantidadDepositos(  cantidadDepositos );

    }

    const decargarExcel = () => {

        let newFiltro = getParametros();
        newFiltro.accept = "application/vnd.ms-excel";
        dispatch( descargaArchivo( newFiltro) );
    }

    const decargarPdf = ()=>{

        let newFiltro = getParametros();
        newFiltro.accept = "application/pdf";
        dispatch( descargaArchivo(newFiltro) );
    }

    const getParametros = ()=>{
        const fecha1 = moment(filtro.fecha_inicial);
        const fecha2 = moment(filtro.fecha_final);
        const params = {
            "fecha_inicial":fecha1.format(format),
            "fecha_final":fecha2.format(format),
            "chkSoloRetiros": filtro.chkSoloRetiros,
            "chkSoloDepositos": filtro.chkSoloDepositos };
        return {
            cdc_uuid: cdc_uuid,
            params: params
        }
    }

    //#endregion

    //region events
    const onClickViewEstadoPersonalizado = ()=>{
        let newFiltro = getParametros();
        dispatch( isRefreshing(true) );
        dispatch( verEstadocuentaPersonalizado(cdc_uuid, newFiltro.params) )
        dispatch( isRefreshing(false) );
    }

    const onClickExcelEstadoPersonalizado = () =>{
        decargarExcel();
    }

    const onClickPdfEstadoPersonalizado = () =>{
        decargarPdf();
    }
    //endregion

    return (
        <>
            <SoftBox pl={1}  mb={1} pr={2}   >
                <Card  sx={{ borderRadius:0 }} >
                    <Grid container  >
                        <Grid item xs={12} md={4} xl={4} textAlign={"center"}>


                        </Grid>
                        <Grid item xs={12} md={4} xl={4} textAlign={"center"}>
                            <SoftTypography color={"color5"}  fontWeight={"bold"} sx={{ fontSize:18 }} > { transacciones.rfc } </SoftTypography>
                            <SoftTypography color={"color5"}  fontWeight={"bold"} sx={{ fontSize:18 }}  > { transacciones.empresa } </SoftTypography>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4} textAlign={"right"} >
                            <IconButton variant={ "text" } color={"dark"} title={"Refrescar los datos consultados"}
                                        sx={{ fontSize:18 }}
                                        onClick={  onClickViewEstadoPersonalizado }
                            >
                                <i className="fa-solid fa-arrows-rotate"></i>
                            </IconButton>
                            <IconButton variant={ "text" } color={"dark"} title={"Exportar a Excel"} disabled={ downloading }
                                        sx={{ fontSize:18 }}
                                        onClick={  onClickExcelEstadoPersonalizado }
                            >
                                <i   className="fa-solid fa-file-excel"></i>
                            </IconButton>
                            <IconButton  variant={ "text" } color={"dark"} title={"Exportar como PDF"} disabled={ downloading }
                                         sx={{ fontSize:18 }}
                                         onClick={  onClickPdfEstadoPersonalizado }
                            >
                                <i className="fa-regular fa-file-pdf"></i>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container  >
                        <Grid item xs={12} md={12} xl={12} textAlign={"center"} >
                            <SoftTypography variant={"h6"} > Saldos y Movimientos en Banca ALPHA ERP </SoftTypography>
                        </Grid>
                    </Grid>
                    <Grid container  >
                        <Grid item xs={12} md={8} xl={8} pl={1} >
                            <SoftTypography variant={"caption"} > De la cuenta <b>{ transacciones.clabe }</b> de la fecha { transacciones.desde } a la fecha { transacciones.hasta } </SoftTypography><br/>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} > <b>Fecha:</b> { transacciones.dia } { transacciones.hora } </SoftTypography><br/>
                        </Grid>
                    </Grid>
                </Card>

            </SoftBox>
            <SoftBox className={"edo-cuenta"} pl={1} pr={1} sx={{ height: (windowHeight - 120 )+"px",position:"relative", overflow:"auto", minHeight: (windowHeight-120)+"px",marginTop:1, marginBottom:0 }} >
                <TableEstadoCuenta borderWidth={1}
                                   columns={columns}
                                   rows={ transaccionesLiquidadas }
                                   rowHeaders={ <RowHeaders transacciones={ transacciones } />}
                                   rowTotalOperaciones = { <TotalOperaciones
                                       depositos={ dataOperaciones?.Depositos }
                                       label={ dataOperaciones?.Detalle }
                                       saldo={ dataOperaciones?.Saldo }
                                       retiros={ dataOperaciones?.Retiros }
                                       />}
                                   rowsEnTransito={ transaccionesEnTransito }
                                   rowTotalOperacionesEnTransito= { <TotalOperaciones
                                       depositos={  transaccionesEnTransitoTotal?.Depositos }
                                       label={      transaccionesEnTransitoTotal?.Detalle   }
                                       saldo={      transaccionesEnTransitoTotal?.Saldo     }
                                       retiros={    transaccionesEnTransitoTotal?.Retiros   }
                                   />}
                                   total={ <Total
                                       depositos={  total?.Depositos }
                                       label={      total?.Detalle   }
                                       saldo={      total?.Saldo     }
                                       retiros={    total?.Retiros   }
                                   />}
                />
                <SoftBox pl={1} mb={1} pt={0}   >
                    <SumarioOperaciones cantidadRetiros={ cantidadRetiros } cantidadDepositos={cantidadDepositos} />
                </SoftBox>
            </SoftBox>

        </>

    )
}
