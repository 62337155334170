import axios from 'axios';

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';

axios.defaults.withCredentials = true;

export default axios.create({
    baseURL: process.env.REACT_APP_API_CORE
});


