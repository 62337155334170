/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SignIn from "layouts/authentication/sign-in";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// @mui material components
import Grid from "@mui/material/Grid";

import Swal from 'sweetalert2';

import api_core from '../../services/config.js'; 

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
import IconSearch from '@mui/icons-material/Search';

import CentrosDeCostosListado from "./Components/CentrosDeCostosListado.jsx";
import CentroItem from "./Components/CentroItem.jsx";

/* componenets */


function CentrosDeCostos() {
  
  const [centroSelected, setCentroSelected] = useState(null);

  const selectedCentro = (centro)=>{
    setCentroSelected(centro);
  }

  const handleVolverAPrincipal = ()=>{
    setCentroSelected(null);
  }

  //Si ya esta autenticado devolvemos el dashboard si no lo mandamos a loguearse

    return (
        <DashboardLayout>
          <DashboardNavbar />
          <SoftBox py={3}>

            { !centroSelected && <CentrosDeCostosListado key="cdcdsPespectiva" selectedCentro = { (centro) => selectedCentro(centro) }  /> }
            { centroSelected && <CentroItem  centroDeCosto = { centroSelected  } handleVolverAPrincipal= {  ()=> handleVolverAPrincipal() }  />  }  
            

          </SoftBox>
      </DashboardLayout>
    );
  
}

export default CentrosDeCostos;
