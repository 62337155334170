/**
=========================================================
* Banca Castelec - v0.0.0
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useDispatch, useSelector } from "react-redux";
import { doLogin } from "store/thunk/auth";

/* componenets */

export const  TestLogin = () => {

    const { user, token } = useSelector( (state) => state.auth )
    const dispatch = useDispatch();

    const params = { 
        email: 'jsanjuan@castelec.com.mx',
        password: 123456
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>

                <SoftButton color="error" onClick={ ()=> dispatch( doLogin(params) )     }>
                    Iniciar Sesión
                </SoftButton>
              
            </SoftBox>
      </DashboardLayout>
    );
  
}

export default TestLogin;