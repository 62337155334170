/**
=========================================================
* Banca Castelec - v0.0.0
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Swal from 'sweetalert2';
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Filtro, Ordenes } from "./components";
import data from "layouts/dashboard/components/Projects/data";

/* componenets */

function OrdenesDeDispersion() {

    const date = new Date();
    const date2 = new Date(date.getFullYear(),date.getMonth() + 1 , 0 );

    
    const [params, setParams] = useState(/*{
        fechaaltaantesde: `${ date.getFullYear() }-${ date.getMonth() }-01`,
        fechaaltadespuesde : `${ date2.getFullYear() }-${ date2.getMonth() }-${ date2.getDay() }`,
    }*/);


    const onApliyFilter = (inputValues)=>{
        if(inputValues.fechaaltaantesde != '' && inputValues.fechaaltadespuesde != '' ){
            
            const fechaaltadespuesde = new Date(inputValues.fechaaltadespuesde);
            const fechaaltaantesde = new Date(inputValues.fechaaltaantesde);
            const diff =  fechaaltaantesde.getTime() - fechaaltadespuesde.getTime() ;
            if(diff < 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Notificaciones',
                    html: 'El rango de fechas es inválido',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }
        }
        
        setParams(inputValues);
    } 

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Filtro onApliyFilter= { onApliyFilter } />
                <Ordenes filter = { params } />
            </SoftBox>
      </DashboardLayout>
    );
  
}

export default OrdenesDeDispersion;