
import {estadoCuentaViewing, isDownloading, saveFiltro, saveLoading, savePeriodo} from 'store/slice/estadoCuentaSlice';
import CentroDecostos from "../../Repository/CentroDeCosto";

export const verEstadocuenta =  (uuid, periodo)=>{
    const centrox = new CentroDecostos();
    return async(dispatch, getState)=>{
        dispatch( saveLoading(true) );
        const result = await centrox.estadoDeCuentaData(uuid, periodo);
        if(result.status == 'ok'){
            dispatch( savePeriodo( periodo ) )
            dispatch( estadoCuentaViewing(result.response ) );
            dispatch( saveFiltro({
                fecha_inicial: "",
                fecha_final: ""
            }) );
        }

    }
}

export const verEstadocuentaPersonalizado =  (uuid, data)=>{
    const centrox = new CentroDecostos();
    return async(dispatch, getState)=>{
        dispatch( saveLoading(true) );
        const result = await centrox.estadoDeCuentaDataPersonalizado(uuid, data);
        if(result.status == 'ok'){
            dispatch( savePeriodo("") );
            dispatch( estadoCuentaViewing(result.response ) );
            dispatch( saveFiltro( data ));
        }

    }
}

export const descargaArchivo = (params)=>{
    const centrox = new CentroDecostos();
    return async(dispatch, getState)=>{
        dispatch( isDownloading(true) );
        await centrox.getEstadoDeCuentaPersonalizado(params.cdc_uuid, params.params, params.accept);
        dispatch( isDownloading(false) );
    }
}