import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Swal from 'sweetalert2';

import IconBussines from '@mui/icons-material/Business'
import IconVpnKey from '@mui/icons-material/VpnKey'


function ActionMenu( { onSelectedMenu } ) {

    const handleClickMenu = (event, value)=>{
        onSelectedMenu(value);
    }

    return(
        <>
            <Grid container spacing={1} alignItems="center" pb={1} pl={3}>
                
                <Grid item xs={12} md={4} xl={2}>
                    <SoftBox >
                        <SoftButton fullWidth color="color6V"  onClick={ (event) => handleClickMenu(event, 3) } >
                            <IconBussines /> Autorizadores
                        </SoftButton>
                    </SoftBox>
                </Grid>
                
                <Grid item xs={12} md={4} xl={2}>
                    <SoftBox >
                        <SoftButton fullWidth color="color6V"  onClick={ (event) => handleClickMenu(event, 1) } >
                            <IconBussines /> Empresas
                        </SoftButton>
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={4} xl={2}>
                    <SoftBox >
                        <SoftButton fullWidth   color="color6V"  onClick={ (event) => handleClickMenu(event, 2) } >
                            <IconVpnKey /> Licencias
                        </SoftButton>
                    </SoftBox>
                </Grid>

            </Grid>
        </>
    )
    
}

ActionMenu.propTypes = {
    onSelectedMenu: PropTypes.func
};

export default ActionMenu;