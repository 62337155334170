import React, {   useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

import IconAddTask from '@mui/icons-material/AddTask';
import IconMenu from '@mui/icons-material/Menu';
import Icon from "@mui/material/Icon";
import IconAdd from "@mui/icons-material/Add"
import SoftInput from "components/SoftInput";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import GridCdcs from '../../personas/components/GridCdcs';
//
import CuentaRequest from '../../../Repository/Cuentas';
import Empresas from 'Repository/Empresas';
import Licencias from 'Repository/Licencias';

import { Card } from '@mui/material';
import AddCentroDeCosto from './AddCentroDeCosto';
import CentroDeCosto from 'Repository/CentroDeCosto';
import Loading from 'examples/Components/Loading';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({ color:"white", "borderStyle": "groove", "borderRadius": "5px", "fontSize":".8em","heigth":"5px",
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(121, 181, 221, .9)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ItemCuenta({uuid,  onUpdate, refresh}) {

    const [visibleCdcs, setVisibleCdcs ] = useState(false);
    const [records, setRecords] = useState([]);
    const [recordsOrigin, setRecordsOrigin] = useState([]);
    const [txtBuscar, setTxtBuscar] = useState('');
    const [cuenta, setCuenta ] = useState({});
    const [idClienteCdc, setIdClienteCdc] = useState('');

    const [EmpresaList, setEmpresaList] = useState([]);
    const [SerieList, setSerieList] = useState([]);

    const [formOpen, setFormOpen] = useState(false)
    const [cdcToEdit, setCdcsToEdit] = useState({});
    const [loading, setLoading] = useState(false);

    const [resetFormCentroDeCosto, setResetFormCentroDeCosto] = useState(0);

    const cuentax = new CuentaRequest();
    const empresax = new Empresas();
    const licenciax = new Licencias();
    const cdcsx = new CentroDeCosto();
    
    //#region "events"
    useEffect(()=>{
        loadData();

    },[]);
    //#endregion

    //#region methods
    const loadData = async (  )=>{
        setLoading(true);
        cuentax.mostrar(uuid).then(response=>{
            setCuenta(response.response.data);
            cuentax.Cdc(uuid).then(response=>{
                setRecords(response.response.data);
                setRecordsOrigin(response.response.data);
                setLoading(false);
                console.log(response.response.data.length);
                if(response.response.data.length == 1){
                    setExpanded(() => ( response.response.data[0].rfc + response.response.data[0].serie ));
                }
            });
        });

        const dataEmpresa = await empresax.listar(uuid);
        const data = dataEmpresa.response.data.map(empresa=>{
            return {
                value: empresa.uuid,
                label: empresa.rs
            }
        }) /*dataEmpresa.response.data*/;

        setEmpresaList([
            {
                value: '',
                label: 'SELECCIONE UNA OPCIÓN'
            },
            ...data
        ]);

        const dataSeries = await licenciax.listar(uuid);
        const data1 = dataSeries.response.data.map(element => {
            return {
                value: element.uuid,
                label: element.serie
            }
        });
        setSerieList([
            {
                value: '',
                label: 'SELECCIONE UNA OPCIÓN'
            },
            ...data1
        ]);

        
    }

    const handleChangeBusqueda = (event)=>{
        setTxtBuscar(event.target.value);
        const filter = recordsOrigin.filter( item => (item.rfc.toLowerCase().includes(event.target.value) || item.serie.includes(event.target.value)  )  );
        setRecords(filter);
    }

    const onAddCdc = ()=>{
        loadData();
    }

    const onCancelCdc = () =>{
        setFormOpen(false);
        setCdcsToEdit({});
    }

    const onClickOpenForm = ()=>{
        getConsecutivo();
        setFormOpen(true);
        setResetFormCentroDeCosto( resetFormCentroDeCosto + 1 );
        loadData();
        
    }

    const onSelectToEdit = (cdcs_uuid)=>{
        setFormOpen(true);
        cdcsx.mostrar(cdcs_uuid).then((response) =>{
            setCdcsToEdit(response.response.data);
        });
        setIdClienteCdc('');
    }

    const getConsecutivo = async ()=>{
        const data = await cdcsx.getCliente();
        setIdClienteCdc(()=>data.response.data);
    }

    //#endregion

    //#region  Accordion
      
        const [expanded, setExpanded] = useState('panel1');
      
        const handleChange = (panel) => (event, newExpanded) => {
            setExpanded(() => (newExpanded ? panel : false));
        };
    //#endregion

    return(
        <>
        <Loading loading={loading} />
        <Divider />
        <Card  >
            <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor="grey-100"
                borderRadius="lg"
                p={3}
                mt={2} ml={2} mr={2} mb={1}
                >
                <SoftBox width="100%" display="flex" flexDirection="column">
                    <Grid container >
                        <Grid item xs={10} md={10} xl={10}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography variant="h6" > Cuenta: { cuenta.alias ? cuenta.alias :'CUENTA SIN ALIAS' } </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={10} md={2} xl={2}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftButton  color="color6A" size="small" onClick={ (event) => onClickOpenForm(event) } title="Agregar Centro de Costos" >
                                    <IconAdd />
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>

                    { formOpen && <AddCentroDeCosto cuenta_uuid={uuid} empresasList={EmpresaList} seriesList={SerieList} 
                        onAdd = { onAddCdc } onCancel = { onCancelCdc } editItem={ cdcToEdit } reset = { resetFormCentroDeCosto } 
                        idClienteCdc = { idClienteCdc }
                        /> }

                    <SoftBox  display="flex" 
                        justifyContent="space-between"
                        alignItems="center" pb={1} mt={2}
                        >
                        <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                                Centros de Costos
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                            <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}  
                                >
                                <SoftBox pl={1} >
                                    <SoftInput placeholder="Buscar centro de costos" type="text"
                                    value={txtBuscar}
                                    onChange={ (event)=> handleChangeBusqueda(event) } 
                                    />
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                {  records.map((cdc) =>{
                        return  (
                         
                            <Accordion key={"accord-"+cdc.rfc+cdc.serie} expanded={expanded === (cdc.rfc+cdc.serie)  } onChange={ handleChange( cdc.rfc+cdc.serie )}>
                                <AccordionSummary key= {"accsum-"+cdc.rfc+cdc.serie} >
                                    <Typography variant='caption' key= {"acctyp1-"+cdc.rfc+cdc.serie}> RFC : { cdc.rfc } Serie :   {cdc.serie} </Typography>
                                </AccordionSummary>
                                <AccordionDetails key= {"accd-"+cdc.rfc+cdc.serie}>
                                    <GridCdcs key={"card-"+cdc.rfc+cdc.serie} centroDeCosto={cdc} onSelectToEdit = { (value) =>onSelectToEdit(value) } refresh={refresh} 
                                        
                                    /> 
                                </AccordionDetails>
                            </Accordion>
                        )
                    })  }

                </SoftBox>
            </SoftBox>
        </Card>
            
        </>
    )
    
}

ItemCuenta.propTypes = {
    uuid: PropTypes.string,
    onUpdate : PropTypes.func,
    refresh: PropTypes.any
  };

export default ItemCuenta