import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";

import IconClose from "@mui/icons-material/Close"
import IconEdit from "@mui/icons-material/Edit"
import IconAdd from "@mui/icons-material/Add"

import Licencias from 'Repository/Licencias';
import EstatusV2 from './EstatusV2';
import AddLicencia from './AddLicencia';

function ListadoLicencias({cuenta_uuid, onCloseComponent}) {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const [dataToEdit, setDataToEdit] = useState( {} );
    const [formOpen, setFormOpen] = useState(false) 
    const [reset, setReset] = useState(0);

    const licenciax = new Licencias();

    useEffect(()=>{
        listar();
    },[])

    //#region methods
    const listar = ()=>{
        licenciax.listar(cuenta_uuid).then(response=>{
            setColumns(
                [
                  { name: "Licencia", align: "left" },
                  { name: "Estatus", align: "center" },
                  { name: "Acciones", align: "center" },
                ]
                );
                let item = [];
                (response.response.data).forEach(element => {
                  
                  let row = { 
                    Licencia: (
                        <>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                                <SoftTypography variant="h5" > { element.serie }  </SoftTypography>
                            </SoftBox>
                        </>
                    )  ,
                    Estatus:  <EstatusV2 key={element.uuid} item= {element} currentObject={ Licencias } onStateUpdatetem ={ (event)=>onUpdateStateItemEmpresas(event) } />,
                    Acciones: (
                        <>
                            <SoftButton fullWidth variant="text"  color="color7A" size="large" onClick={ (event) => onSelectEditItem(event, element.uuid) } title="Editar" >
                                <IconEdit /> Editar
                            </SoftButton>
                        </>
                    )
                  };
                  item = [...item, row];
                });
                setRows(item);
        })
    }
    //#endregion

    //#region events
    const onAddHandle = ()=>{
        listar();
    }

    const onClickCloseComponent = ()=>{
        onCloseComponent();
    }

    const onItemChanged = ()=>{
        //
        console.log('lo cambiaron de estatus we');
    }

    const onSelectEditItem = (event, value)=>{
        licenciax.mostrar(value).then(response=>{
            if(response.status  == 'ok'){
                let data = response.response.data;

                //console.log(data);
                setDataToEdit({
                    ...dataToEdit, 
                    'cuenta_uuid': cuenta_uuid,
                    'uuid': data.uuid,
                    'serie': data.serie,
                });
                setFormOpen(true);
            }else{

            }
            
        })
    }

    const onClickOpenForm= ()=>{
        setFormOpen(true);
        setReset(reset +1 );
    }

    const onCancelHandle = ()=>{
        setFormOpen(false);
        setDataToEdit({
            ...dataToEdit, 
            'cuenta_uuid': '',
            'uuid': '',
            'serie': '',
        });
    }

    const onUpdateStateItemEmpresas = (event)=>{
        listar();
    }

    //#endregion

    return(
        <>
            <SoftBox pt={1}>
                <Card>

                    <Grid container >
                        <Grid item xs={8} md={4} xl={8}>
                            <SoftBox   alignItems="center" p={3}>
                                <SoftTypography > Licencias </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            <SoftButton    color="color6A"  size="small" onClick={ (event) => onClickOpenForm(event) } title="Agregar"   >
                                <IconAdd /> 
                            </SoftButton>
                            <SoftButton    color="error" size="small" onClick={ (event) => onClickCloseComponent(event) } title="Cerrar esta sección" >
                                <IconClose /> 
                            </SoftButton>
                        </Grid>
                    </Grid>

                    { formOpen &&  <AddLicencia uuid = { cuenta_uuid } onAdd = { onAddHandle } onCancel={ onCancelHandle }  data = { dataToEdit } reset={ reset } /> }

                    
                    <SoftBox
                        sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                        }}
                        >
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoLicencias.propTypes = {
    cuenta_uuid: PropTypes.string,
    onCloseComponent: PropTypes.func
  };

export default ListadoLicencias;