import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';
import IconAddTask from '@mui/icons-material/AddTask';
//
import PersonasRequest from '../../../Repository/Personas';
import SoftBadge from "components/SoftBadge/index.js";

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function ListadoPersonas({subtitle,additionalFilter ={}, onSelectedPersona}) {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    let personax = new PersonasRequest();

    const handleCheckChange = ({target})=>{
        //setState( target.checked ) ;
        //cambiarEstado(item, target.checked,  Persona.cambiarEstado);
    }

    const handleRefresh = ()=> listar();

    //#region events
    const handleChangeBusqueda = ({target})=>{
        const value = target.value;
        setTxtBuscar(value);
        if(value.length > 3){
          listar(value);
        }else if(value == ''){
          listar('');
        }
        
    }

    const handleSeleccionar = (event, uuid, nombres_completo)=>{
        onSelectedPersona(uuid, nombres_completo)
    }

    //#endregion

    //#region methods
    function listar(textox = ''){
        let params = {nombres:textox, soloActivos: true};
        if(additionalFilter.hasOwnProperty('cuenta_uuid')){  params.cuenta_uuid = additionalFilter.cuenta_uuid; }
        personax.listar(params).then(response=>{
          
          setColumns(
          [
            { name: "Nombre", align: "left" },
            { name: "Correo", align: "center" },
            { name: "Celular", align: "center" },
            { name: "Empresa", align: "center" },
            { name: "OTP", align: "center" },
            { name: "Seleccionar", align: "center" },
          ]
          );
          let item = [];
          (response.response.data).forEach(element => {
            
            let row = { 
              Nombre:  
                             element.nombres+" "+ element.apellidos
                         ,
              Correo:element.email,
              Celular:element.celular,
              Empresa: <SoftTypography variant="caption">
                    { element.referencia }
                  </SoftTypography>,
              OTP:  
                    (element.otpSecret && element.otpLastUse) ? (
                    <SoftBadge variant={  (element.otpSecret && element.otpLastUse) ? "gradient": "contained"  } badgeContent={   " otp "  } size="lg" container />
                    ):''
                ,
              Seleccionar: ( !element.disabled &&
                <SoftButton  color="color6A" onClick={ (event)=> handleSeleccionar(event,element.uuid,`${element.nombres} ${element.apellidos}`) } iconOnly >
                    <IconAddTask fontSize="36px"  color="white" title="Seleccionar" />
                </SoftButton>
              )
                     
            };
            item = [...item, row];
          });
          setRows(item);
          
          
        });
      }
    //#endregion

    
    useEffect(()=>{
        listar();
    },[]);

    return(
        <>
            <SoftBox mb={3}>
                <Card>
                    <Grid container>
                        <Grid item xs={8} md={3} xl={2}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography >{subtitle}</SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item pl={1} xs={3} md={3} xl={2} pt={2}>
                            <SoftButton  color="color5V" onClick={handleRefresh}  >
                                <IconRefresh fontSize="36px"  color="white" title="Actualizar" /> Refrescar
                            </SoftButton>
                        </Grid>
                        <Grid item xs={8} md={1} xl={3}>

                        </Grid>
                        <Grid item xs={8} md={5} xl={5} pt={2} pr={3}>
                            <SoftBox >
                                <SoftInput placeholder="Buscar" type="text"
                                value={txtBuscar}
                                onChange={ (event)=> handleChangeBusqueda(event) } 
                                />
                            </SoftBox>
                        </Grid>

                    </Grid>
                    <SoftBox
                        sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                        }}
                        >
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoPersonas.propTypes = {
    subtitle: PropTypes.string.isRequired,
    onSelectedPersona: PropTypes.func,
    additionalFilter: PropTypes.object
};

export default ListadoPersonas;