import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    logged: 'logout',
    user : null,
    token: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
            state.token = action.payload.token;
            state.logged = 'logged';
        },
        logout: (state) => {
            state.logged = 'logout';
            state.user = null;
        },
        checking:(state) => {
            state.logged = "checking";
        }
    }
})

// Action creators are generated for each case reducer function
export const { login, logout, checking } = authSlice.actions

export default authSlice.reducer