import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {checking, login, logout} from '../store/slice/authSlice';
import {useLocation, useNavigate} from "react-router-dom";

export const useCheckAuth = () => {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { logged,user } = useSelector(state => state.auth );
    const dispatch = useDispatch();
    const sesionUser = localStorage.getItem("session");

    useEffect(() => {
        dispatch( checking() );
        if(!sesionUser) {
            dispatch( logout() );
        }else{
            dispatch( login( JSON.parse( sesionUser ) ) );
            //TODO:: Si se refresca completamente la pantalla no debemos volver a dasboard, este es solo un comodin
            //if( pathname != lastPage ){
            navigate(pathname);
            //}
        }
    }, [sesionUser]);

    return {
        logged,
        user
    }

}