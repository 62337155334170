import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';

import OrdenClass from '../../../../Repository/Ordenes';
import { Typography } from '@mui/material';

export const Ordenes = ({filter}) => {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 
    const [cursor, setCursor] = useState(null);
    const orden = new OrdenClass();

    //#region events
    const onClickOds = () => {

    }

    useEffect (()=>{
        let args = {};
        if(filter?.cdcs != undefined){
            args.cdcs = filter.cdcs ;
        }
        if(filter?.fechaaltaantesde != undefined){
            args.fechaaltaantesde = filter.fechaaltaantesde ;
        }
        if(filter?.fechaaltadespuesde != undefined){
            args.fechaaltadespuesde = filter.fechaaltadespuesde ;
        }
        listar(args);
    },[filter])

    const onCursorClick = (type)=>{
        if(cursor){
            let query = '';
            if(type === 'next')
            {
                query = cursor.next;
            }else{
                query = cursor.prev;
            }
            listarPorCursor(query);
        }
    }
    //#endregion

    //#region methods
    const  listar = async (params) =>{
        const result = await orden.listarOperaciones(params);
        fillTable(result);
    }

    const listarPorCursor = async (query) =>{
        const result = await orden.listarOperacionesCursor(query);
        fillTable(result);
    }
    
    const fillTable = (response)=>{
        
        setColumns(
          [
            { name: "RFC", align: "left" },
            { name: "Serie", align: "center" },
            { name: "Cliente STP", align: "center" },
            { name: "Nombre STP", align: "left" },
            { name: "Razon Social", align: "left" },
            { name: "Dispersiones", align: "right" },
            { name: "Abonos", align: "right" },
            { name: "Total", align: "right" },
            { name: "Rechazadas", align: "right" },
          ]
          );
          let item = [];
          (response.response.data).forEach(element => {
            const rfc = <SoftTypography variant='caption'>{ element.rfc.toUpperCase() }</SoftTypography>;
            const serie = <SoftTypography variant='caption'>{ element.serie }</SoftTypography>;
            const cliente = <SoftTypography variant='caption'>{ element.idCliente }</SoftTypography>;
            const stp = <SoftTypography variant='caption'>{ (element.stpNombre != null ?element.stpNombre.toUpperCase()  :'')  }</SoftTypography>;

            const total = parseInt(element.abonos) + parseInt(element.dispersiones);
            const totalLabel = <SoftTypography color={ total == 0? "color7":"color1" } variant='button'>{ ( total ).toLocaleString('es-MX')  }</SoftTypography>;
            
            const dispersion = parseInt(element.dispersiones);
            const dispersionLabel = <SoftTypography color={ dispersion == 0? "color7":"color1" }  variant='button'> { dispersion.toLocaleString('es-MX') } </SoftTypography>;

            const rechazadas = parseInt(element.rechazadas);
            const rechazadasLabel = <SoftTypography color={ rechazadas == 0? "color7":"color1" } variant='button'> { rechazadas.toLocaleString('es-MX') } </SoftTypography>;

            const abonos = parseInt(element.abonos);
            const abonosLabel = <SoftTypography  color={ abonos == 0? "color7":"color1" }  variant='button'> {abonos.toLocaleString('es-MX') } </SoftTypography>;

            let row = { 
                "RFC":   rfc,
                "Serie":   serie,
                "Cliente STP" : cliente,
                "Nombre STP" : stp,      
                "Razon Social": (element.rs).toUpperCase() ,
                Dispersiones: dispersionLabel,
                Abonos: abonosLabel,
                'Total': totalLabel,
                'Rechazadas': rechazadasLabel
            };
            item = [...item, row];
          });

          const tdispersion     = parseInt(response.response.totales.liquidado);
          const tabonos         = parseInt(response.response.totales.abonos);
          const ttotales        = parseInt(response.response.totales.totales);
          const trechazadas     = parseInt(response.response.totales.rechazadas);

          let row = { 
            "RFC":  <SoftTypography>Totales</SoftTypography>,
            "Serie":   '',
            "Cliente STP" : '',
            "Nombre STP" : "",      
            "Razon Social": "" ,
            Dispersiones:<SoftTypography color={ tdispersion == 0? "color7":"color1" }> {tdispersion.toLocaleString(`es-MX`) } </SoftTypography> ,
            Abonos:<SoftTypography color={ tabonos == 0? "color7":"color1" }> { tabonos.toLocaleString(`es-MX`) } </SoftTypography> ,
            'Total':<SoftTypography color={ ttotales == 0? "color7":"color1" }> {ttotales.toLocaleString(`es-MX`) } </SoftTypography> ,
            'Rechazadas':<SoftTypography  color={ trechazadas == 0? "color7":"color1" }> { trechazadas.toLocaleString(`es-MX`) } </SoftTypography> 
        };
        item = [...item, row];

          setRows(item);
          setCursor(()=>response.response.cursor);
    }

    //#endregion

    return (
        <>
                
                    
                <SoftBox pt={2}>
                    <Table columns={columns} rows={rows} />
                </SoftBox>
                

                <Grid container >
                    <Grid item xs={10} md={8} xl={8}>
                        
                    </Grid>
                    <Grid item xs={10} md={2} xl={2}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftButton  color="color6A" size="small" fullWidth onClick={ ()=> onCursorClick('prev') } >
                                <SoftTypography variant="caption" color="white" > {"<< Anterior"} </SoftTypography>
                            </SoftButton>
                            
                        </SoftBox>
                    </Grid>
                    <Grid item xs={10} md={2} xl={2}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftButton  color="color6A" size="small" fullWidth onClick={ ()=> onCursorClick('next') } >
                                <SoftTypography variant="caption" color="white" > {"Siguiente >>"} </SoftTypography>
                            </SoftButton>
                        </SoftBox>
                    </Grid>
                </Grid>
          
        </>
    )
}

Ordenes.propTypes = {
    filter: PropTypes.any
};

export default Ordenes;