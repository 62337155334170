
import {login} from 'store/slice/authSlice';

export const doLogin =  (credentials)=>{

    return async(dispatch, getState)=>{

        /*
        const response = await  persona.loggin(credentials);
        if(response.status == 'error'){
            dispatch( logout() );
            sessionStorage.clear();
        }else {
            dispatch(login(response));
            sessionStorage.setItem("session", JSON.stringify( response.response ) );
            console.log(getState().auth);
        }
        */
        localStorage.setItem("cToken", credentials.token);
        localStorage.setItem("session", JSON.stringify( credentials ) );
        localStorage.setItem("cRefreshToken", credentials.refreshToken );

        dispatch(login(credentials));
    }
    
    
}