import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import SoftButton from "components/SoftButton";
import IconEmail from '@mui/icons-material/Email';
import Swal from 'sweetalert2';

//
import Persona from '../../../Repository/Personas';



const SendNuevaContrasenia =  ({persona_uuid}) => {

    const personax = new Persona();
    //#region events
    const onClickBtnEnviarNotificacion = (event)=>{
        enviarCorreo(persona_uuid );
    }
    //#endregion

    const enviarCorreo = async (persona_uuid)=>{
        const result = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Desea envíar el correo de recuperación de contraseña?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            //input: 'password',
            allowOutsideClick: false
            });

        if(result.isConfirmed){
            personax.enviarRenovarContrasenia( persona_uuid).then(response =>{
                if(response.status ='ok'){
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }else{
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }
            });
        }
        
    }

    return(
        <>
            <SoftButton color="color1" fullWidth onClick={ onClickBtnEnviarNotificacion }  >
                <IconEmail fontSize="36px"  color="white" title="Borrar" /> Restablecer contraseña
            </SoftButton>
        </>
    )
    
}

SendNuevaContrasenia.propTypes = {
    persona_uuid: PropTypes.string,
};

export default SendNuevaContrasenia;