import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/authSlice'
import counterSlice from './slice/counterSlice'
import personaSlice from "./slice/personaSlice";
import estadoCuentaSlice from "./slice/estadoCuentaSlice";
import ventanaSlice from "./slice/ventanaSlice";

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    auth: authSlice,
    persona: personaSlice,
    estadocuenta: estadoCuentaSlice,
    ventana: ventanaSlice
  },
})