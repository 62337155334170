import React, { Component, useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2';

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
//
import Persona from '../../../Repository/Personas';
import SendOtpActivate from './SendOtpActivate';
import OptDesactivate from './OptDesactivate';
import ButtonEstatusPersona from './ButtonEstatusPersona';
import Loading from 'examples/Components/Loading';
import SendPrimerCorreo from './SendPrimerCorreo';
import SendNuevaContrasenia from './SendNuevaContrasenia';
import {useDispatch, useSelector} from "react-redux";
import {personaCancelEdit} from "../../../store/slice/personaSlice";
import {editPersona} from "../../../store/thunk/persona";
import Select from "react-select";
import {useAlert} from "../../../customHooks/useAlert";

const initialItemSelect = {
    value: '',
    label: 'SIN NOTIFICACIÓN'
}

function Profile({ onCancel}) {
    const personax = new  Persona();
    const dispatch = useDispatch();
    const {currentRecord : viewPersona, uuid, config: personaConfig, tipoNotificaciones: catTipoNotificaciones } = useSelector(state => state.persona);
    const [errores, setErrores] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [referencia, setReferencia] = useState('');
    const [disabled, setDisabled] = useState();
    const [ tipoNotificacion_uuid, setTipoNotificacionUuid ] = useState('');
    const [ selectedItemNotificacion, setSelectedItemNotificacion ] = useState( initialItemSelect );
    const [periodo, setPeriodo] = useState();
    //errors
    const [spanReferencia, setSpanReferencia] = useState(false);
    const { showAlert } = useAlert();

    const [hasOtp, setHasOtp ] = useState(false);

    const onChangeTipoNotificacion = (event)=>{
        setTipoNotificacionUuid(event.value);
        setSelectedItemNotificacion( event );
    }

    /* Nombres de los autorizadorees start   */
    function getErrNombres(negative){
        if(negative){
        if(nombres.length < 3) return true;
            return false;
        }else{
            if(nombres.length > 3 ) return true;
            return false;
        }
    }

    function getErrApellidos(negative){
        if(negative){
            if(apellidos.length < 3) return true;
            return false;
        }else{
        if(apellidos.length > 3 ) return true;
            return false;
        }
    }
  
    function getErrCelular(negative){
        if(negative){
            let restmpx = celular.match(/^\d{10}$/g)
            if(restmpx == null) return true;
            return false;

        }else{
        let restmpx = celular.match(/^\d{10}$/g)
        if(restmpx) return true;
            return false;
        }
    }

    function getErrEmail(negative){
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(negative){
            
            if(!emailRegex.test(email)) return true;
            return false;

        }else{
            
            if(emailRegex.test(email)) return true;
            return false;
        }
    }

    function validate(){
        let hasError = false;
        let counter = 0;
        if(nombres.length < 3) {counter+=1; }
        if(apellidos.length < 3){counter+=1; }
        if(referencia.length < 3){counter+=1; }
    
        let restmpx = celular.match(/^\d{10}$/g)
        if(restmpx == null) {counter+=1; }

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(!emailRegex.test(email)) {counter+=1; }

        if(counter > 0){
        return (hasError = true);
        }
        let error = false;
        if(selectedItemNotificacion.label != 'SIN NOTIFICACIÓN'){
            error = (tipoNotificacion_uuid == '' || tipoNotificacion_uuid == undefined || tipoNotificacion_uuid == null) && (periodo == '' || periodo == undefined);
        }
        if( error) return true;
        if( selectedItemNotificacion.label != 'SIN NOTIFICACIÓN' && (periodo == undefined || periodo == '')) {
            showAlert('Ups', "Ingrese un periodo", "warning");
            return true;
        };
        const rangoInvalido = selectedItemNotificacion.label != 'SIN NOTIFICACIÓN' && ( parseInt(periodo) <= 0 || parseInt(periodo) > 23 );
        if( rangoInvalido ){
            showAlert('Ups', "Ingrese un periodo de 1 a 23 horas", "warning");
            return true;
        }
    
        return hasError;
    }

    const doActualizar = async  ()=>{
        const result = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Desea continuar con la actualización de datos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
            });
           
            
            if(result.isConfirmed){
                let params = {
                        uuid: uuid,
                        apellidos: apellidos,
                        nombres : nombres,
                        celular: celular,
                        email: email,
                        referencia: referencia,
                        nt_uuid : tipoNotificacion_uuid != undefined ? tipoNotificacion_uuid: null,
                        periodo: (periodo ? periodo: null)
                    }
                    await personax.update(params, uuid).then((response)=>{
                        if(response.status == 'ok'){
                            Swal.fire({
                                title: 'Notificaciones',
                                text: response.response.text,
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            });
                            dispatch( editPersona(uuid) );
                        }else{
                            Swal.fire({
                                title: 'Notificaciones',
                                text: response.response.text,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            });
                        }
                    });
            }
            
    }

    /* Nombres de los autorizadorees end   */
    const handledActualizarDatos = (event)=>{    
        event.preventDefault();
        if(!validate()){
            doActualizar();
        }
    }

    const onOtpCanceled = ()=>{
        setHasOtp(false);
    }

    useEffect(()=>{
        //mostrarPersona();
        setLoading(true);
        setApellidos(  viewPersona.apellidos );
        setNombres( viewPersona.nombres );
        setEmail(  viewPersona.email );
        setCelular( viewPersona.cel );
        setReferencia( viewPersona.referencia != null ? viewPersona.referencia:  '' );
        setDisabled((viewPersona.disabled ==  null ? true: false ) );
        setHasOtp( (viewPersona.otpSecret !='' && viewPersona.otpSecret != null && viewPersona.otpLastUse != null ? true : false) );
        setLoading(false);
        setPeriodo( personaConfig?.data?.periodo );
        setTipoNotificacionUuid( personaConfig?.nt_uuid );
        if(personaConfig?.nt_uuid ){
            const selected = catTipoNotificaciones.find(element => element.value == personaConfig.nt_uuid);
            setSelectedItemNotificacion(selected);
        }
        //TODO::poner el tipo de notificación seleccionado al momento de cargar la edición
    },[]);

    
    useEffect(()=>{
        if(referencia != null && referencia != undefined)
        {
            if(referencia.length < 3){
                setSpanReferencia(()=> true);
            }
            else{
                setSpanReferencia(()=> false);
            }
        }
    }, [referencia]);
    

    const handledCancelar = (event)=>{
       dispatch( personaCancelEdit() );
        onCancel();
    }

    return(
   
        <SoftBox py={3}>
          <Loading loading={ loading } />
            <Card  >
                <SoftBox p={3} mb={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                        Persona
                    </SoftTypography>
                    <SoftBox mb={1}>
                        <SoftTypography variant="button" fontSize="xxs" color="error" >
                            { errores }
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox mt={1} mb={2}  >
                        <Grid container  >
                            
                            <Grid item xs={12} md={2} xl={2} >
                                { !hasOtp && <SendOtpActivate persona_uuid={uuid} />   }
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} pl={1}>
                               { hasOtp &&  <OptDesactivate persona_uuid={uuid} onOtpCanceled = { onOtpCanceled } />  }
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <SendPrimerCorreo persona_uuid={uuid}  />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <SendNuevaContrasenia persona_uuid={uuid}  />
                            </Grid>

                        </Grid>
                    </SoftBox>

                    <SoftBox component="form" role="form">

                        <Grid container spacing={3} pt={2}>

                            <Grid item xs={12} md={6} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Nombres
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput type="text" placeholder="Nombres"   value={nombres}
                                    error={ getErrNombres(true) } success={ getErrNombres(false)}
                                    onChange={ e => setNombres(e.target.value) } 
                                    maxLength={10} autoComplete="given-name"
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={8}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Apellidos
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput type="text" placeholder="Apellidos"   value={apellidos}
                                    error={ getErrApellidos(true) } success={ getErrApellidos(false)}
                                    onChange={ e => setApellidos(e.target.value) } 
                                    maxLength={10} autoComplete="additional-name"
                                    />
                                </SoftBox>
                            </Grid>



                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Empresa
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput placeholder="Empresa" maxLength="10"
                                    value = {referencia}
                                    error={ spanReferencia } 
                                    onChange={ e => setReferencia(e.target.value) } 
                                    
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Celular
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput placeholder="Celular" maxLength="10"
                                    value = {celular}
                                    error={ getErrCelular(true) } success={ getErrCelular(false)}
                                    onChange={ e => setCelular(e.target.value) } 
                                    
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Correo
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput placeholder="Correo" type="email"
                                    value={email}
                                    error={ getErrEmail(true) } success={ getErrEmail(false)}
                                    onChange={ e => setEmail(e.target.value) } 
                                    autoComplete="email"
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Tipo de Notificación
                                    </SoftTypography>
                                </SoftBox>

                                <Select  onChange={ onChangeTipoNotificacion }
                                         className="basic-single"
                                         classNamePrefix="select"
                                         isSearchable={true}
                                         name={"tipoNotificacion"}
                                         options={ catTipoNotificaciones }
                                         value={ selectedItemNotificacion }
                                         menuPosition={'fixed'}
                                         styles={{
                                             container: (base) => ({
                                                 ...base,
                                                 fontSize:"14px",
                                                 fontFamily:"Arial"
                                             }),
                                             option: (base) => ({
                                                 ...base,
                                                 fontSize:"12px",
                                                 fontFamily:"Arial"
                                             })
                                         }}
                                />

                            </Grid>

                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Notificar cada(Horas / No aplica para UNITARIO)
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput placeholder="Periodo" type="number"
                                               value={periodo}
                                               onChange={ e => setPeriodo(e.target.value) }
                                               autoComplete="periodo"
                                    />
                                </SoftBox>
                            </Grid>


                        </Grid>

                        

                    </SoftBox>

                    

                    <SoftBox mt={1} mb={2}  >
                        <Grid container  >
                            <Grid item xs={12} md={3} xl={3}>
                                { disabled != undefined  &&  <ButtonEstatusPersona key="23234234" uuid={ uuid  } estatus = { disabled } /> }
                            </Grid>
                            <Grid item xs={12} md={3} xl={4}>
                                
                            </Grid>
                            <Grid item xs={12} md={3} xl={2} >
                                <SoftButton fullWidth  color="color9" onClick={ handledCancelar} >
                                    <IconBack /> Volver al listado
                                </SoftButton>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} pl={1}>
                               
                                <SoftButton fullWidth color="color6V" onClick={ handledActualizarDatos} >
                                   <IconSave /> Actualizar datos
                                </SoftButton>
                            </Grid>
                        </Grid>
                    </SoftBox>

                </SoftBox>

            </Card>
        </SoftBox>
        
    )
    
}

Profile.propTypes = {
    uuid: PropTypes.string,
    onCancel: PropTypes.func
  };

export default Profile;