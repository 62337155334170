
import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";
import IconButton from "@mui/material/IconButton";
import SoftButton from "../../../../components/SoftButton";
import CentroDeCosto from "../../../../Repository/CentroDeCosto";
import {useNumberFormat} from "../../../../customHooks/useNumberFormat";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import PrintComprobante from "./printComprobante";
import {forwardRef, useRef} from "react";

function DetalleCell( { element } ) {
    const comprobanteRef = useRef(null);
    const handlePrint = useReactToPrint({
        content:()=>comprobanteRef.current
    });
    const { numberFormat } = useNumberFormat();
    const centrox = new CentroDeCosto();
    const buttonImprimir = <SoftButton variant={"text"} size={"small"} color={"dark"} title={"Imprimir esta operación"}
                                       sx={{ fontSize:12,textTransform:"none", paddingTop:"30px" }}
                                       onClick={ handlePrint }
    >
        <i className="fa-solid fa-print" ></i> <SoftTypography pl={1} variant={"caption"}>Imprimir</SoftTypography>
    </SoftButton>

    const btnDescargaCep =  <SoftButton variant={"text"} size={"small"} color={"dark"} title={"Descarga PDF y XML del CEP"}
                                        sx={{ fontSize:12,textTransform:"none", paddingTop:"30px" }}
                                        onClick={ ()=> onClickCepBanxico( element ) }
    >
        <i className="fa-regular fa-file-lines"></i> <SoftTypography pl={1} variant={"caption"}>Recibo Banxico</SoftTypography>
    </SoftButton>

    const onClickDescargar= (transaccion)=>{
        centrox.imprimirComprobante(transaccion.uuid, transaccion.tipo, 'pdf');
    }

    const onClickCepBanxico = (transaccion)=>{
        centrox.imprimirComprobante(transaccion.uuid, transaccion.tipo, 'zip');
        //console.log( transaccion.uuid, transaccion.tipo.toLowerCase() );
    }

    return (
        <>

            <SoftBox sx={{ display:"none" }}>
                <PrintComprobante ref={ comprobanteRef } props = { element } />
            </SoftBox>

            <Grid container columns={12} >
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"} fontWeight={"bold"}>Cuenta Origen:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"}>{ `${element.ordenante} ${ (element.cuentaOrdenante) }` }</SoftTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"} fontWeight={"bold"}>Cuenta destino:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"}>{ `${element.beneficiario} ${ (element.cuentaBeneficiario) }` }</SoftTypography>
                </Grid>

            </Grid>
            <Grid container columns={12} >
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"}  fontWeight={"bold"}>Banco Origen:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"}> { element.banco_ordenante } </SoftTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"}  fontWeight={"bold"}>Banco destino:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"}> { element.banco_beneficiario } </SoftTypography>
                </Grid>
            </Grid>

            <Grid container columns={12} >
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"}  fontWeight={"bold"}>Fecha y hora:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"}> { element.fecha_hora } </SoftTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"}  fontWeight={"bold"}>Número de referencia:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"}> { element.referenciaNumerica } </SoftTypography>
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"}  fontWeight={"bold"} >Clave rastreo:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"} >{ element.claveRastreo }</SoftTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox>
                        <SoftTypography variant={"caption"}  fontWeight={"bold"} >Monto:</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant={"caption"} >{ numberFormat(element.monto,2)  }</SoftTypography>
                </Grid>

            </Grid>
            <Grid container >
                <Grid item xs={12} md={12} xl={12} textAlign={"center"}>

                    <SoftButton variant={"text"} size={"small"} color={"dark"} title={"Descarga esta operación"}
                                sx={{ fontSize:12,textTransform:"none", paddingTop:"30px" }}
                                onClick={ ()=> onClickDescargar(element) }
                    >
                        <i className="fa-solid fa-download"></i> <SoftTypography pl={1} variant={"caption"}>Descargar</SoftTypography>
                    </SoftButton>
                    { buttonImprimir }
                    { element.esSpei === 'SPEI' && element.estado === 'LIQUIDADO' ?  btnDescargaCep : '' }

                </Grid>
            </Grid>



        </>
    );
}

// Typechecking props for the ListItem
DetalleCell.propTypes = {
    element: PropTypes.object.isRequired
};

export default DetalleCell;
