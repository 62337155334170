import { Routes, Navigate, Route } from "react-router-dom"
import routes from "../routes";
import routespages from "../route-pages";
import {useSelector} from "react-redux";

export const PanelRoutes = () => {
    const { user } = useSelector(state=> state.auth);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                const attachRoute = route.role.find (element => element == user.role );
                if(attachRoute){
                    return <Route exact path={route.route} element={route.component} key={route.key} />;
                }
            }

            return null;
        });

    return (
        <Routes>
            { getRoutes(routes) }
            { getRoutes(routespages) }
            <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
    )
}