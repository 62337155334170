/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
// Data
import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import CentroDeCosto from "../../Repository/CentroDeCosto";
import CentroItem from "../configuracion/components/CentroItem.jsx";
import SoftBadge from "../../components/SoftBadge";
import {v4 as uuidv4} from "uuid";
import Swal from "sweetalert2";
import {consultarMiNotificacionConsigurada, consultaTiposNotificaciones, editPersona} from "../../store/thunk/persona";
import Personas from "../../Repository/Personas";
import SoftTypography from "../../components/SoftTypography";
import Select from "react-select";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import IconSave from "@mui/icons-material/Save";
import Card from "@mui/material/Card";
import {useAlert} from "../../customHooks/useAlert";

const initialItemSelect = {
    value: '',
    label: 'SIN NOTIFICACIÓN'
}

function Configuracion() {
    const dispatch = useDispatch();
    const { tipoNotificaciones: catTipoNotificaciones, config: personaConfig } = useSelector(state => state.persona);
    const [selectedItemNotificacion, setSelectedItemNotificacion] = useState(initialItemSelect);
    const [periodo, setPeriodo] = useState('');
    const [ tipoNotificacion_uuid, setTipoNotificacionUuid ] = useState('');
    const [centros, setCentros] = useState([]);
    const { showAlert } = useAlert();
    const centrox = new CentroDeCosto();
    const personax = new Personas();

    useEffect(() => {
        const personaSesion = JSON.parse( localStorage.getItem('session') );
        dispatch( consultarMiNotificacionConsigurada( personaSesion.uuid ) )
        load();
    }, [])

    useEffect(()=>{
        dispatch( consultaTiposNotificaciones() );
    }, [])

    useEffect(()=>{
        if(personaConfig != undefined){
            setPeriodo( personaConfig?.data?.periodo );
            setTipoNotificacionUuid( personaConfig?.nt_uuid );
            if(personaConfig?.nt_uuid ){
                const selected = catTipoNotificaciones.find(element => element.value == personaConfig.nt_uuid);
                setSelectedItemNotificacion(selected);
            }
        }
    }, [personaConfig])

    //#region methods
    const load = async ()=>{
        const data = await centrox.getCentrosDeCostoPorPersona();
        if(data.status != 'ok') return;
        setCentros( data.response );
    }

    const permisosBtns = () =>{
        return Object.keys(permisosInit).map( property => {
            const checked   = notificacionesLocal[`${ property }`];
            const label     = notificacionesLocal[`${ property }`] ?  permisosInit[`${ property }`][true]: `${ permisosInit[`${ property }`][false] }`;
            return <SoftBadge key={ uuidv4() }
                              badgeContent={ label }
                              size="xs" container
                              color={ checked ? "info":"light"}
                              sx={{ paddingRight:"2px", cursor:"pointer" }}
                              onClick={ () => handleCheckChange(property ) }
            />
        })
    }

    const onChangeTipoNotificacion= (event)=>{
        setTipoNotificacionUuid(event.value);
        setSelectedItemNotificacion( event );
    }

    const handledActualizarConfiguracionPeriodica = ()=>{
        let error = false;
        if(selectedItemNotificacion.label != 'SIN NOTIFICACIÓN'){
            error = (tipoNotificacion_uuid == '' || tipoNotificacion_uuid == undefined || tipoNotificacion_uuid == null) && (periodo == '' || periodo == undefined);
        }
        if( error) return false;
        if( selectedItemNotificacion.label != 'SIN NOTIFICACIÓN' && (periodo == undefined || periodo == '')) {
            showAlert('Ups', "Ingrese un periodo", "warning");
            return false;
        };
        const rangoInvalido = selectedItemNotificacion.label != 'SIN NOTIFICACIÓN' && ( parseInt(periodo) <= 0 || parseInt(periodo) > 23 );
        if( rangoInvalido ){
            showAlert('Ups', "Ingrese un periodo de 1 a 23 horas", "warning");
            return false;
        }

        const params = {
            nt_uuid : tipoNotificacion_uuid != undefined ? tipoNotificacion_uuid: null,
            periodo: (periodo ? periodo: null)
        }

        actualizarPeriodoNotificaciones(params);

    }

    const actualizarPeriodoNotificaciones = async (params)=>{
        const sesionPersona = JSON.parse( localStorage.getItem('session') );
        await personax.updateNotificacionesPeriodicas(params, sesionPersona.uuid).then((response)=>{
            if(response.status == 'ok'){
                showAlert("Notificaciones", response.response.text, "success");
            }else{
                showAlert("Notificaciones", response.response.text, "error");
            }
        });
    }

    //#endregion

    return (
        <DashboardLayout >
            <DashboardNavbar />
            <SoftBox >
                <Card >
                    <Grid container mt={1} pl={1}>
                        <Grid item xs={12} md={6} xl={6}>
                            <SoftBox bgColor={"light"} pr={1} sx={{ borderRadius:2, border:1 }} >
                                <SoftBox pl={1}>
                                    <SoftTypography sx={{ fontSize:14, fontWeight:"bold" }} >
                                        Notificaciones periodicas
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox pl={1}>
                                    <SoftTypography sx={{ fontSize:12 }} >
                                        Recibe el resumen o detalle de las operaciones que se realizarón a tu correo electrónico.
                                        Las operaciones que recibiras son (dispersiones en dictamen, dispersiones liquidadas, abonos recibidos).
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} pl={1} pr={1} mb={1}>
                            <Grid container >
                                <Grid item xs={12} md={6} xl={6}>
                                    <SoftBox mb={1} lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Tipo de Notificación
                                        </SoftTypography>
                                    </SoftBox>

                                    <Select  onChange={ onChangeTipoNotificacion }
                                             className="basic-single"
                                             classNamePrefix="select"
                                             isSearchable={true}
                                             name={"tipoNotificacion"}
                                             options={ catTipoNotificaciones }
                                             value={ selectedItemNotificacion }
                                             menuPosition={'fixed'}
                                             styles={{
                                                 container: (base) => ({
                                                     ...base,
                                                     fontSize:"12px",
                                                     fontFamily:"Arial",
                                                     zIndex:999
                                                 }),
                                                 option: (base) => ({
                                                     ...base,
                                                     fontSize:"12px",
                                                     fontFamily:"Arial"
                                                 })
                                             }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} xl={6} pl={1} pr={1}>
                                    <SoftBox mb={1} lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            Notificar cada(Horas)
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox >
                                        <SoftInput placeholder="Periodo" type="number"
                                                   value={periodo}
                                                   onChange={ e => setPeriodo(e.target.value) }
                                                   autoComplete="periodo"
                                        />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={12} xl={12} pt={1}>
                                    <SoftButton fullWidth size={"small"} color="color6V" onClick={ handledActualizarConfiguracionPeriodica} >
                                        <IconSave /> Actualizar
                                    </SoftButton>
                                </Grid>
                            </Grid>



                        </Grid>
                    </Grid>
                </Card>
            </SoftBox>
            <SoftBox mt={1}>
                {
                    centros.map(element=>{
                        return <CentroItem key={ element.uuid } centro = { element  }  />
                    })
                }
            </SoftBox>




        </DashboardLayout>
    );

}

export default Configuracion;
