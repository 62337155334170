

import PropTypes from "prop-types";
import {Stack} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

export const Loader = () => {
    return (
        <Stack sx={{ width: '100%', color: 'grey.500', paddingTop:40 }} spacing={2}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
        </Stack>
    )
}

export default Loader;