import React, {  useState, useEffect } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
//
import Centro from '../../../Repository/CentroDeCostoAutorizadores';

import EstatusV2 from './EstatusV2';
import AddAutorizadosCdcs from './AddAutorizadosCdcs';
import { DictamenCDC } from './DictamenCDC';
import PermisoCheck from "./PermisoCheck";
import IconButton from "@mui/material/IconButton";
import Notificaciones from "./Notificaciones";

import { v4 as uuidv4 } from "uuid";
import SoftButton from "../../../components/SoftButton";
import PermisosYNotificaciones from "./PermisosYNotificaciones";


function ListadoAutorizadoresCdcs({centro, refresh}) {

    let [statex, setState] = useState( (centro.disabled == null || centro.disabled == true ? true: false) );
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 
    const centrox = new Centro();
    //#region events
        useEffect(()=>{
            listar();
        },[])

        const onAutorizadorCdcsAdded = ()=>{
            listar();
        }

        const onUpdateStateItemAutorizadores = ()=>{}
    //#endregion

    //#region methods
        const listar = async ()=>{
            await centrox.listar(centro.cdcs_uuid).then(response=>{
                setColumns(
                    [
                        { name: "Autorizador", align: "left", width:"30%" },
                        { name: "Permisos", align: "left", width:400 },
                        { name: "Estatus", align: "center", width:"30%" },
                    ]
                    );
                    let item = [];
                    (response.response.data).forEach(element => {
                      
                      let row = { 
                        'Autorizador':  element.nombre.toUpperCase()  ,
                        "Permisos": (
                            <PermisosYNotificaciones autorizador_uuid={ element.uuid } permisos={ element.permisos }  notificaciones={ element.notificaciones } />

                          ),
                        Estatus: (
                           <EstatusV2 key={element.uuid} item= {element} currentObject={ Centro } onStateUpdatetem ={ (event)=>onUpdateStateItemAutorizadores(event) } />
                        )
                      };
                      item = [...item, row];
                    });
                    setRows(item);
            })
        }
    //#endregion
    
    
    return(
        <>
            <SoftBox pt={1} mb={2}>
                <Card>

                    <Grid container >
                        <Grid item xs={10} md={8} xl={10}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography variant="h6" > Autorizadores del Centro de Costos </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={10} md={8} xl={2}>
                           
                        </Grid>
                    </Grid>
                    <SoftBox>
                        <AddAutorizadosCdcs  cdcs_uuid={centro.cdcs_uuid} onAdd={ onAutorizadorCdcsAdded } refresh = { refresh } />
                        <DictamenCDC uuid={centro.cdcs_uuid} />
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoAutorizadoresCdcs.propTypes = {
    centro: PropTypes.object,
    refresh: PropTypes.any
  };

export default ListadoAutorizadoresCdcs;