import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import ListadoPersonas from 'layouts/personas/components/ListadoPersonas';

export default function ModalBusqueda({isOpen,title, onOpenForm, onSelectedItem, additionalFilter = {} }) {
  const [open, setOpen] = React.useState(isOpen);
  const [scroll, setScroll] = React.useState('paper');
  const handleClose = (event) => {
    setOpen(false);
    //onOpenForm(event);
    onOpenForm(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  

  const onSelectedPersona = (uuid, nombres)=>{
   
    onSelectedItem(uuid, nombres);
  }

  return (
    <div>
      
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth = 'xl'
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <ListadoPersonas subtitle="Buscar" additionalFilter={ additionalFilter } onSelectedPersona = { (uuid, nombres) =>onSelectedPersona(uuid, nombres) } />
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose} >Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalBusqueda.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenForm: PropTypes.func,
    title: PropTypes.string.isRequired,
    onSelectedItem : PropTypes.func,
    additionalFilter: PropTypes.object //Parametros adicionales para restringir los datos a mostrar en el listado de personas
};