import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
// @mui material components
import Card from "@mui/material/Card";
import Swal from 'sweetalert2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import GridPlazas from 'layouts/personas/components/GridPlazas';

import CentroDeCostoRequest from 'Repository/CentroDeCosto';
import ProfilesList from 'examples/Lists/ProfilesList';
import ListItem from './ListItem';
import CentroDeCosto from 'Repository/CentroDeCosto';
import { Divider, Grid } from '@mui/material';
import { display } from '@mui/system';
import CuentaInfoCard from "./CuentaInfoCard";


function CentroItem({centroDeCosto }) {

    const [verCdcs, setVerCdcs] = useState(false);
    const [estadosDeCuenta, setEstadosDeCuenta] = useState([]);
    const [statex, setStatex] = useState(centroDeCosto.disabled == null ? true: false );
    const centrox = new CentroDeCosto();
    //#region events
    
        const handleSeleccionar = (event)=>{
            setVerCdcs(!verCdcs);
        }

        useEffect(() => {
            load();
        }, [])
        
    //#endregion

    //#region  methods

    //#region methods
    const load = async ()=>{
        const {response = [], status} = await centrox.getEstadosDeCuentaDisponibles( centroDeCosto.uuid );
        setEstadosDeCuenta(response);
    }
    //#endregion
      
    //#endregion 
   

    return(
        <>
            <CuentaInfoCard key={ centroDeCosto.uuid } cdc_uuid={ centroDeCosto.uuid } cuenta={ centroDeCosto.rs } centros={ estadosDeCuenta } info={ [
                { label: 'CLABE', value: centroDeCosto.clabe  },{ label: 'RFC', value: centroDeCosto.rfc  }, { label: 'Licencia', value: centroDeCosto.serie  }
            ]} />

        </>
    )
    
}

//persona_uuid,  cuenta_uuid , rfc, serie
CentroItem.propTypes = {
    uuid: PropTypes.string,
    centroDeCosto: PropTypes.object
    //onSelectToEdit: PropTypes.func,
    //inPersona: PropTypes.bool
  };

export default CentroItem;