import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

import IconSearch from "@mui/icons-material/Search";
import IconAdd from "@mui/icons-material/Add";

import Swal from 'sweetalert2';

import ModalBusqueda from '../ModalBusqueda';
import Autorizador from 'Repository/Autorizador';

function AddAutorizador({cuenta_uuid, onAdd}) {

    const [customerSelected, setCustomerSelected] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [uuidSelected, setUuidSelected] = useState('');
    const autorizadorx = new Autorizador();

    const showSearchPopup = (event)=>{
        event.preventDefault();
        setIsOpenModal(true);
    }

    const onHandleOpenForm = (event)=>{
        setIsOpenModal(false);
    }

    const handleOnSelectedItem = (uuid, nombre_completo)=>{
        setIsOpenModal(false);
        setUuidSelected(uuid);
        setCustomerSelected(nombre_completo)
    }

    const onAddItem = (event) =>{
        const params = {
            cuenta_uuid: cuenta_uuid,
            persona_uuid: uuidSelected
        } 
        
        autorizadorx.add(params).then((response)=>{
            
           if(response.status =="ok"){
            Swal.fire({
                icon: 'success',
                title: 'Notificaciones',
                html: response.response.text,
                showConfirmButton: true,
                confirmButtonText: 'Aceptar'
              }).then((value)=>{
                setCustomerSelected('');
                setUuidSelected('');
                onAdd();
              })
           }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Notificaciones',
                    html: response.response.text,
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
           }
            
        })
    }

    return(
       <>
            <ModalBusqueda isOpen={isOpenModal} title="Buscar Autorizador" additionalFilter={ {'cuenta_uuid': cuenta_uuid} }  onOpenForm={ (value)=>onHandleOpenForm(value) }
                    onSelectedItem = { (uuid, nombre_completo) => handleOnSelectedItem(uuid, nombre_completo) }/>
            <SoftBox pt={2} pb={3} px={3} component="form" role="form">
                <Grid container spacing={2} >
                    <Grid item xs={6} md={7} xl={7}>
                        <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                                Agregar Persona Autorizadora
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                            <SoftInput type="test" placeholder="" 
                            maxLength="10"  value = {customerSelected}
                            autoComplete="off"
                            disabled
                            />
                        </SoftBox>
                    </Grid>

                    <Grid item xs={3} md={2} xl={2}>
                        <SoftBox pt={3} >
                            <SoftButton fullWidth color="color5A"  onClick={showSearchPopup} >
                                <IconSearch /> Buscar
                            </SoftButton>
                        </SoftBox>
                    </Grid>
                    {( uuidSelected &&
                        
                        <Grid item xs={3} md={3} xl={3}>
                            <SoftBox pt={3} >
                                <SoftButton fullWidth  color="color5V"  onClick={ (event) => onAddItem(event) } >
                                    <IconAdd /> Agregar seleccionada
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    
                    )}
                    
                </Grid>
            </SoftBox>
            
       </>
    )
    
}

AddAutorizador.propTypes = {
    cuenta_uuid: PropTypes.string,
    onAdd: PropTypes.func,
  };

export default AddAutorizador;