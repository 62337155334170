import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import IconDelete from '@mui/icons-material/Delete';

import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import Swal from 'sweetalert2';

import Persona from 'Repository/Personas';



function ButtonActivar2FA({ uuid , onActivated }) {

    const activate2fa = (event)=>{
        operaciones(uuid, Persona.activate2Fa);
    }

    const  operaciones  = async (uuid, /* callback*/  callback)=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Está seguro de continuar con la activación de la autenticación de dos factores?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
            }).then((result)=>{
                if(result.isConfirmed){
                    callback(uuid).then((response) =>{
                        if(response.status == 'ok'){
                            Swal.fire({
                                title: 'Notificaciones',
                                text: response.response.text,
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                                });
                                onActivated(uuid);
                        }else{
                            Swal.fire({
                                title: 'Notificaciones',
                                text: response.response.text,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                                });
                        }
                    }); 
                }
            })  
    }

    return(
        <>
            <SoftBox mt={3} lineHeight={1}>
            <SoftBox
                sx={{
                  display: "flex",
                  mt: 2,
                }}
              >
                
                <SoftButton
                  color="info"
                  variant={"gradient"}
                  onClick={activate2fa}
                  fullWidth
                >
                  Activar autenticación de dos factores
                </SoftButton>
              </SoftBox>
            </SoftBox>
        </>
    )
    
}

ButtonActivar2FA.propTypes = {
    uuid : PropTypes.string.isRequired,
    onActivated: PropTypes.func
  };

export default ButtonActivar2FA;