
// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CentroDeCosto from "Repository/CentroDeCosto";
import { Divider, Grid } from "@mui/material";

function ListItem( {cdc_uuid,centros = [], title} ) {

  const centrox = new CentroDeCosto();

  const onClickEstado = (periodo)=>{
    download(periodo);
  }

  //#region 
    const download =  async (periodo)=>{
      centrox.getEstadoDeCuenta(cdc_uuid,periodo);
    }
  //#endregion

    const renderProfiles = centros.map( (centro ) => (
        <SoftBox key={ centro.periodo } component="li" display="flex" alignItems="center" py={1} mb={1}  >
        <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
        >
            <SoftTypography variant="button" fontWeight="medium">
            {centro.nombre_periodo}
            </SoftTypography>
        </SoftBox>
        <SoftBox ml="auto">
            <SoftButton onClick={ ()=> onClickEstado(centro.periodo) }
                rel="noreferrer"
                color="color5V"
                variant="outlined"
                size="small"
            >
                <FileDownloadIcon  /> Descargar
            </SoftButton>
        </SoftBox>
        </SoftBox>
    ));

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="none">
          { ( 'Estados de cuenta')}
        </SoftTypography>
      </SoftBox>
      <Divider />
      <SoftBox p={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the ListItem
ListItem.propTypes = {
  cdc_uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  centros: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ListItem;
