
import Operacion from './Operacion';

class Ordenes extends Operacion{

    constructor() {
        super();
    }

    async listar(params){
        let query = ``;
        if( params?.fechaaltadespuesde !== undefined && params?.fechaaltadespuesde !== '' )
        {
            query+=`fechaaltadespuesde=${params?.fechaaltadespuesde}&`;
        }
        if( params?.fechaaltaantesde !==  undefined && params?.fechaaltaantesde !==  '' )
        {
            query+=`fechaaltaantesde=${params?.fechaaltaantesde}&`;
        }
        if( params?.cdcs !==  undefined  && params?.cdcs !==  '' )
        {
            query+=`cdcs=${params?.cdcs}&`;
        }
        const url = `panel/reportes/ordenesdedispersion?${ query }`;
        const response = await this.transaction('GET',url, null , true );
        return response;
    }

    /**
     * 
     * @param string uri 
     * @returns [] objects
     */
    async listarCursor(uri){
        uri = uri.substr(1);
        const response = await this.transaction('GET',uri, null , true );
        return response;

    }

    async listarOperaciones(params){
        let query = ``;
        if( params?.fechaaltadespuesde !== undefined && params?.fechaaltadespuesde !== '' )
        {
            query+=`fechaaltadespuesde=${params?.fechaaltadespuesde}&`;
        }
        if( params?.fechaaltaantesde !==  undefined && params?.fechaaltaantesde !==  '' )
        {
            query+=`fechaaltaantesde=${params?.fechaaltaantesde}&`;
        }
        
        const url = `panel/reportes/ods/operaciones?${ query }`;
        const response = await this.transaction('GET',url, null , true );
        return response;
    }

    /**
     * 
     * @param string uri 
     * @returns [] objects
     */
     async consultarFechas(){
        const uri = `panel/reportes/operaciones/consultarfechas`;
        const response = await this.transaction('GET',uri, null , true );
        return response;

    } 

    /**
     * 
     * @param string uri 
     * @returns [] objects
     */
    async listarOperacionesCursor(uri){
        uri = uri.substr(1);
        const response = await this.transaction('GET',uri, null , true );
        return response;

    }

    async exportarOperaciones ( body = {} ){
        let requestOptions = {
            method:"GET",
        }
      
        requestOptions.headers = {
            'Content-Type': 'application/vnd.ms-excel','Authorization': `Bearer ${localStorage.getItem('cToken')}`
        }
        const params = `?fechaaltadespuesde=${body.fechaaltadespuesde}&fechaaltaantesde=${body.fechaaltaantesde}`;
        try{
            const date = new Date();
            const reporte = `reporte${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.xlsx`;
            await fetch(`${process.env.REACT_APP_API_CORE}${`panel/reportes/operaciones/exportar`}${params}`, requestOptions)
                .then(response => (response.blob()))
                .then((blob)=>{
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                      );
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute(
                        'download',
                        reporte
                      );
                  
                      document.body.appendChild(link);
                  
                      link.click();
                  
                      link.parentNode.removeChild(link);
                })
        }catch(error){
            //console.log(error, "::error");
            return {
                status : 'error',
                response: {
                    text: error
                }
            };
        }
    }

}

export default Ordenes;