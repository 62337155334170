//RECT
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

//Theme components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import IconRefresh from '@mui/icons-material/Refresh';
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table";
import SoftInput from "components/SoftInput";
//Domain class
import CentroDeCosto from "Repository/CentroDeCosto";
import Estatus from "./Estatus";

const CentrosDeCostosListado = ({ selectedCentro }) => {
  
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [texto, setTexto] = useState(''); 

    const cdcsp = new CentroDeCosto
    const navigate = useNavigate();

    //#region events
    useEffect(()=>{
        listar('');
    },[])

    const handleChangeBusqueda = ({target}) =>{
        const value = target.value;
        setTexto(value);
        if(value.length > 3){
          listar(value);
        }else if(value == ''){
          listar('');
        }
    }

    const handleRefresh = ()=>{
        listar('');
    }

    const handleShowClick = (item)=>{
        selectedCentro(item);
    }

    const handleShowCuentaClick = (cuenta_uuid)=>{
        localStorage.setItem(`cCuentaUuid`, cuenta_uuid);
        navigate('/cuentas');
    }
    //#endregion

    //#region methods
    const listar = async (value)=>{
        await cdcsp.listadoEnPerspectiva(value).then(response=>{
            setColumns(
                [
                    { name: "Centro de Costo STP", align: "center"},
                    { name: "Clabe", align: "center" },
                    { name: "Centro de Costo", align: "left" },
                    { name: "Razón Social", align: "left" },
                    { name: "Cuenta", align: "center", title:"Razón Social" },
                    { name: "Cliente", align: "center" },
                    { name: "Estatus", align: "center" },
                ]
                );
                let item = [];
                (response.response.data).forEach(element => {
                  
                    let row = {
                        'Centro de Costo STP':<SoftTypography variant="caption">
                                                {element.idCliente + (element.stpNombre == null ? '': "-"+ element.stpNombre ) }
                                            </SoftTypography>  ,
                        'Clabe':element.clabe,
                        'Centro de Costo':  <SoftTypography
                                                component="a"
                                                href="#"
                                                variant="caption"
                                                color="info"
                                                fontWeight="medium" onClick={ ()=>handleShowClick({...element, 'cdcs_uuid': element.uuid})  }
                                            >
                                            {   element.rfc+" - "+ element.serie}
                                        </SoftTypography>
                                        ,
                        
                        'Razón Social':element.rs,
                        'Cuenta':<SoftTypography
                                        component="a"
                                        href="#"
                                        variant="caption"
                                        color="info"
                                        fontWeight="medium" onClick={ ()=>handleShowCuentaClick(element.cuenta_uuid)  }
                                    >
                                    { element.alias }
                                </SoftTypography>
                        ,
                        'Cliente':element.idCliente,

                        Estatus: (
                            <Estatus key={element.uuid} item={element} clase="CentroDeCosto"   />
                        )
                    };
                    item = [...item, row];
                });
                setRows(item);
        })
    }
    //#endregion
    
    return (
        <>
            <SoftBox pt={1}>
                <Card>

                    
                    <Grid container>
                        <Grid item xs={8} md={3} xl={3}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography > Centros de Costos </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item pl={1} xs={3} md={3} xl={2} pt={2}>
                            <SoftButton  color="color5V" onClick={handleRefresh}  >
                                <IconRefresh fontSize="36px"  color="white" title="Actualizar" /> Refrescar
                            </SoftButton>
                        </Grid>
                        <Grid item xs={8} md={1} xl={2}>

                        </Grid>
                        <Grid item xs={8} md={5} xl={5} pt={2} pr={3}>
                            <SoftBox >
                                <SoftInput placeholder="Buscar" type="text"
                                value={texto}
                                onChange={ (event)=> handleChangeBusqueda(event) } 
                                />
                            </SoftBox>
                        </Grid>

                    </Grid>

                    <SoftBox>
                        <Table columns={columns} rows={rows} />
                    </SoftBox>

                </Card>
            </SoftBox>
        </>
    )

}

CentrosDeCostosListado.propTypes = {
    selectedCentro : PropTypes.func
}

export default CentrosDeCostosListado
