import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";

import IconClose from "@mui/icons-material/Close"
import IconAdd from "@mui/icons-material/Add"
import IconEdit from "@mui/icons-material/Edit"

import Empresas from 'Repository/Empresas';
import AddEmpresa from './AddEmpresa';
import EstatusV2 from './EstatusV2';

function ListadoEmpresas({cuenta_uuid, onCloseComponent}) {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    const [dataToEdit, setDataToEdit] = useState( {} );
    const [formOpen, setFormOpen] = useState(false)
    const [reset, setReset] = useState(0);

    const empresax = new Empresas();

    useEffect(()=>{
        listar();
    },[])

    //#region methods
    const listar = ()=>{
        empresax.listar(cuenta_uuid).then(response=>{
            setColumns(
                [
                  { name: "Nombre Comercial", align: "left" },
                  { name: "RFC", align: "left" },
                  { name: "Razon Social", align: "center", title:"Razón Social" },
                  { name: "Direccion", align: "center" },
                  { name: "Estatus", align: "center" },
                  { name: "Acciones", align: "center" },
                ]
                );
                let item = [];
                (response.response.data).forEach(element => {
                  
                  let row = { 
                    'Nombre Comercial':  element.nombreComercial  ,
                    RFC : element.rfc,
                    'Razon Social':element.rs,
                    'Direccion':<SoftTypography sx={{ fontSize:13, wordWrap: 'break-word', width: '11rem', }} >{ element.direccion }</SoftTypography>,
                    Estatus: (
                        <EstatusV2 key={element.uuid} item= {element} currentObject={ Empresas } onStateUpdatetem ={ (event)=>onUpdateStateItemEmpresas(event) } />
                    ),
                    Acciones : (
                        <>
                            <SoftButton fullWidth variant="text"  color="color7A" size="large" onClick={ (event) => onSelectEditItem(event, element.uuid) } title="Editar" >
                                <IconEdit /> Editar
                            </SoftButton>
                        </>
                    )
                  };
                  item = [...item, row];
                });
                setRows(item);
        })
    }
    //#endregion

    //#region events

    const onClickOpenForm= ()=>{
        setFormOpen(true);
        setReset(reset+1);
    }

    const onCancelHandle = ()=>{
        setFormOpen(false);
        setDataToEdit({
            ...dataToEdit, 
            'cuenta_uuid': '',
            'uuid': '',
            'nombreComercial': '',
            'rfc': '',
            'rs': ''
        });
    }

    const onAddHandle = ()=>{
        listar();
    }

    const onClickCloseComponent = ()=>{
        onCloseComponent();
    }

    const onSelectEditItem = (event, value)=>{
        empresax.mostrar(value).then(response=>{
            if(response.status  == 'ok'){
                let data = response.response.data;

                setDataToEdit({
                    ...dataToEdit, 
                    'cuenta_uuid': cuenta_uuid,
                    'uuid': data.uuid,
                    'nombreComercial': data.nombreComercial,
                    'rfc': data.rfc,
                    'rs': data.rs,
                    'direccion': data.direccion
                });
                setFormOpen(true);
            }else{

            }
            
        })
    }

    const onUpdateStateItemEmpresas = (event)=>{
        listar();
    }
    //#endregion

    return(
        <>
            <SoftBox pt={1}>
                <Card>

                    <Grid container >
                        <Grid item xs={8} md={4} xl={8}>
                            <SoftBox   alignItems="center" p={3}>
                                <SoftTypography > Empresas </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            <SoftButton    color="color6A" size="small" onClick={ (event) => onClickOpenForm(event) } title="Agregar"   >
                                <IconAdd /> 
                            </SoftButton>
                            <SoftButton    color="error" size="small" onClick={ (event) => onClickCloseComponent(event) } title="Cerrar esta sección" >
                                <IconClose /> 
                            </SoftButton>
                        </Grid>
                    </Grid>

                    { formOpen && <AddEmpresa uuid = { cuenta_uuid } onAdd = { onAddHandle } onCancel={ onCancelHandle } data = { dataToEdit } reset= { reset } />  }

                    
                    <SoftBox
                        >
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoEmpresas.propTypes = {
    cuenta_uuid: PropTypes.string,
    onCloseComponent: PropTypes.func
  };

export default ListadoEmpresas;