import React, {useEffect} from 'react'
import {Snackbar} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {useState} from "react";
import {useSelector} from "react-redux";

export const NotificacionesSnack = React.memo(
    () => {
        const [open, setOpen] = useState(true);
        const { downloading } = useSelector(state => state.estadocuenta);

        useEffect(()=>{
            setOpen(downloading);
        },[downloading])

        const anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'right'
        }
        const handleClose = ()=>{
            setOpen( !open );
        }

        return (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                      anchorOrigin={{ vertical: 'bottom',
                          horizontal: 'right' }}
                      message="Estamos generando el documento, espere un momento por favor."
                      key ={ uuidv4() }
            >

            </Snackbar>
        )
    }
)

export default NotificacionesSnack;