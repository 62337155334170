import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
// @mui material components
import Card from "@mui/material/Card";
import Swal from 'sweetalert2';

import GridPlazas from './GridPlazas';

import CentroDeCostoRequest from 'Repository/CentroDeCosto';
import ListadoAutorizadoresCdcs from 'layouts/cuentas/components/ListadoAutorizadoresCdcs';
import {v4} from "uuid";

const  GridCdcs =  ({centroDeCosto, onSelectToEdit,refresh, inPersona = false}) => {
    const [verCdcs, setVerCdcs] = useState(false);
    const [statex, setStatex] = useState(centroDeCosto.estatus_cdcs == null ? true: false );

    const handleSeleccionar = (event)=>{
        setVerCdcs(!verCdcs);
    }

    const handleEditar = (event, uuid)=>{
        onSelectToEdit(uuid);
    }

    const cambiarEstado = async (cdcs_uuid)=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Para confirmar el cambio de estatus del CDC coloque su código de confirmación?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false
            });
        if(passtext.isConfirmed){
           
            CentroDeCostoRequest.cambiarEstado(cdcs_uuid, !statex, passtext.value ).then(response=>{
                if(response.status == 'ok'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Excelente',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    setStatex(!statex);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
            }); 
        }
    }

    const handleCambiarEstado = (event, cdcs_uuid)=>{
        cambiarEstado(cdcs_uuid); 
        
    }

    return(
        <>

            <Card>
                <SoftBox
                        display="flex" bgColor="color6A" color="color10"  opacity={1}
                        justifyContent="space-between"
                        alignItems="center"
                        py={1}
                        pr={1}
                        mb={1} pl={2}
                        >
                    <SoftBox lineHeight={1}>
                        <SoftTypography display="block" color="white" variant="button" fontWeight="medium">
                        RFC: {centroDeCosto.rfc} - SERIE: {centroDeCosto.serie} {centroDeCosto.clabe ? ' - CLABE: ' + centroDeCosto.clabe : ''}
                        </SoftTypography>
                    </SoftBox>
                    
                    <SoftBox display="flex" alignItems="center">
                        { !inPersona &&
                            (
                                <>
                                    
                                    <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}  
                                        onClick={ (event)=> handleEditar(event, centroDeCosto.cdcs_uuid) }>
                                        <Icon fontSize="small">edit</Icon>
                                        <SoftTypography variant="button" fontWeight="bold">
                                            EDITAR
                                        </SoftTypography>
                                </SoftBox>
                                    
                                    <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}  
                                        onClick={ (event)=> handleCambiarEstado(event, centroDeCosto.cdcs_uuid) }>
                                        <SoftTypography variant="button" color={ statex == true ? 'dark':'error'} fontWeight="bold">
                                            { statex == true ? 'ACTIVO ':'INACTIVO '} 
                                        </SoftTypography>
                                    </SoftBox>
                                </>
                            )
                        }
                        
                        
                        <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}  
                        onClick={ (event)=> handleSeleccionar(event) }>
                            <Icon fontSize="small">view_headline</Icon>
                            <SoftTypography variant="button" fontWeight="bold">
                                { verCdcs ? 'OCULTAR ':'VER '} PLAZAS
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>

                { !inPersona && <ListadoAutorizadoresCdcs key={ v4() } centro = {centroDeCosto} refresh={ refresh } /> }

                { verCdcs && (
                    <>
                        <GridPlazas key={ v4() }  cdcs_uuid = {centroDeCosto.cdcs_uuid} />
                    </>
                    
                    
                ) 
                }
            </Card>
        </>
    )
    
}


//persona_uuid,  cuenta_uuid , rfc, serie
GridCdcs.propTypes = {
    centroDeCosto: PropTypes.object,
    onSelectToEdit: PropTypes.func,
    inPersona: PropTypes.bool,
    refresh: PropTypes.any
  };

export default React.memo(GridCdcs) ;