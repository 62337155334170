import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import IconCardRental from '@mui/icons-material/CreditCard';
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Swal from 'sweetalert2';


import Plaza from "../../../Repository/Plaza";

//#region methods
    function cambiarEstado(clabe, value,/* callback */ callback){
        callback(clabe, value).then((response)=>{
            Swal.fire({
                title: 'Notificaciones',
                text: response.response.text,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            });
        });;
    }
//#endregion methods


function ItemPlaza({plaza, onAdd}) {

    const [status, setStatus] = useState(plaza.disabled == null? true : false); 

    const handleChangeSattus = ({target})=>{
        let isChecked = target.checked;
        
         Swal.fire({
            title: 'Confirmaciones',
            text: "¿Desea continuar con el cambio de estado de la plaza?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
            }).then((result)=>{
                if(result.isConfirmed){
                    setStatus(isChecked);
                    cambiarEstado(plaza, isChecked , Plaza.cambiarEstado)
                    onAdd();  
                }else{
                    setStatus( !isChecked );
                }
            });  
    }

    return(
        <>
            <SoftBox key={plaza.cdc} component="li" display="flex" alignItems="center" py={1} >
                <SoftBox mr={2} fontWeight="medium" >
                    <IconCardRental  color="info"   /> {plaza.cdc}
                </SoftBox>
                
                <SoftBox ml="auto">
                    <SoftBox mt={0.25}>
                        <Switch  checked={ (status) } onChange={ (event) => handleChangeSattus(event) }  color="success" /> 
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <Divider />
        </>
    )
    
}

//persona_uuid,  cuenta_uuid , rfc, serie
ItemPlaza.propTypes = {
    plaza: PropTypes.object,
    onAdd: PropTypes.func
  };

export default ItemPlaza;