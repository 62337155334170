import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import IconDelete from '@mui/icons-material/Delete';


import SoftButton from "components/SoftButton";

import Swal from 'sweetalert2';

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function ButtonDelete({ uuid ,currentObject , onDeleteItem}) {

    //const [deleted, setDeleted ] = useState(false);

    const  operaciones  = async ()=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Esta seguro de eliminar al autorizador?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false
            });

        if(passtext.isConfirmed){
            currentObject.delete(uuid,passtext.value).then((response) =>{
                if(response.status == 'ok'){
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                        onDeleteItem(uuid);
                }else{
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }
               
            }); 
        }

    }
    
    const handleOnDelete = (event)=>{
        
        operaciones();
    }

    return(
            <SoftButton variant="gradient" color="error" onClick={ (event) => handleOnDelete(event) } iconOnly >
                <IconDelete fontSize="36px"  color="white" title="Borrar" />
            </SoftButton>
        
    )
    
}

ButtonDelete.propTypes = {
    uuid: PropTypes.string.isRequired,
    currentObject: PropTypes.func,
    onDeleteItem: PropTypes.func.isRequired,
  };

export default ButtonDelete;