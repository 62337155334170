import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Select from 'react-select';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import Switch from "@mui/material/Switch";
import { Card } from '@mui/material';
import Swal from 'sweetalert2';

import SoftButton from "components/SoftButton";
import NativeSelect  from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import SoftInput from "components/SoftInput";

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';

import Licencias from 'Repository/Licencias';
import CentroDeCosto from 'Repository/CentroDeCosto';
import ListadoLicencias from "./ListadoLicencias";


function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

const initialItemSelect = {
    value: '',
    label: 'SELECCIONE UNA OPCIÓN'
}

function AddCentroDeCosto({cuenta_uuid, empresasList = [], seriesList = [], onAdd, onCancel, editItem, reset, idClienteCdc = '' }) {
    
    const [LicenciasCollection, setLicenciasCollection] = useState(seriesList)
    //const [EmpresaUuid, setEmpresaUuid] = useState(   );
    //const [LicenciaUuid, setLicenciaUuid] = useState(  );
    const [idCliente, setIdCliente] = useState(idClienteCdc);
    const [stpNombre, setStpNombre] = useState();
    const [Editing, setEditing] = useState('');
    const [currentEdit, setCurrentEdit] = useState(editItem);
    const [ selectedItemEmpresa, setSelectedItemEmpresa ] = useState( initialItemSelect );
    const [ selectedItemLicencia, setSelectedItemLicencia ] = useState( initialItemSelect );

    const [error, setError] = useState({
        stpNombre: false
    });

    const licenciax = new Licencias();
    const centrox = new CentroDeCosto();

    //#region mmethods
    const load = async ()=>{
        const dataSeries = await Licencias.listar(cuenta_uuid);
        const data1 = dataSeries.response.data;
        setLicenciasCollection(data1);
    }
    //#endregion

    //#region eventos
       
        useEffect(()=>{
            if(editItem){
                setEditing( editItem.uuid );
                //setEmpresaUuid( editItem.empresa_uuid );
                //setLicenciaUuid( editItem.licencia_uuid );
                setIdCliente( editItem.idCliente );
                setStpNombre( editItem.stpNombre ? editItem.stpNombre : '' );
                setCurrentEdit(editItem);
                setLicenciasCollection(LicenciasCollection);

                if(editItem?.empresa_uuid){
                    const empresa = empresasList.find( element => { return element.value == editItem.empresa_uuid });
                    setSelectedItemEmpresa( empresa );
                    const licencia = LicenciasCollection.find( licencia => { return licencia.label == editItem.serie; })
                    setSelectedItemLicencia( licencia );
                }
            }
        }, [editItem])

        useEffect(()=>{
            if(reset > 0){
                setEditing('' );
                //setEmpresaUuid( '' );
                //setLicenciaUuid( '' );
                setStpNombre( '' );
                setCurrentEdit('');
                setSelectedItemEmpresa( initialItemSelect );
                setSelectedItemLicencia( initialItemSelect );
            }
        }, [reset])

        
        useEffect(()=>{ 
            
                const regex = new RegExp("^[a-zA-Z0-9_]+$");
                if (!regex.test(stpNombre) ) {
                    setError({
                        ...error,
                        stpNombre: true
                    })
                }else{
                    setError({
                        ...error,
                        stpNombre: false
                    })
                }
            
            
        }, [stpNombre])
        
        

        useEffect(()=>{
            setIdCliente(idClienteCdc);
        }, [idClienteCdc])

        const onChangeEmpresa = (event)=>{
            //TODO::revisar para que no me bloquee el item a la hora de la edicion
            setSelectedItemEmpresa( event );
            setSelectedItemLicencia( initialItemSelect );
            if(event.value == '' ) {
                setLicenciasCollection([]);
                setSelectedItemLicencia( selectedItemLicencia );
                return;
            };
            licenciax.licenciasDisponibles( cuenta_uuid ,event.value).then(response=>{
                const data = response.response.data.map(licencia=>{
                    return {
                        value: licencia.uuid,
                        label: licencia.serie
                    }
                });
                setLicenciasCollection( data);
            });
        }

        const onChangeLicencia = (event)=>{
            setSelectedItemLicencia(event);
        }

        const handleCrearCDC = ()=>{

            if(selectedItemEmpresa.label ==  'SELECCIONE UNA OPCIÓN' ){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Seleccione una empresa para poder continuar',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }
            if(selectedItemLicencia.label ==  'SELECCIONE UNA OPCIÓN'){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Seleccione una licencia para poder continuar',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }

            if(stpNombre.length == 0 || idCliente.length == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Ingrese los datos relacionados a STP',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }

            const params = {
                cuenta_uuid,
                empresa_uuid : selectedItemEmpresa.value,
                licencia : selectedItemLicencia.value,
                idCliente: idCliente,
                stpNombre: stpNombre 
            }

            if (Editing == '' | Editing == undefined ){
                centrox.store(params).then(response=>{
                    if(response.status == 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                        onAdd();
                        setIdCliente('');
                        setStpNombre('');
                        setSelectedItemEmpresa( initialItemSelect );
                        setSelectedItemLicencia( initialItemSelect);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Notificaciones',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                    }
                })
            }else{
                centrox.update(params, Editing ).then(response=>{
                    if(response.status == 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                        onAdd();
                    }else{
                        //console.log(response);
                        Swal.fire({
                            icon: 'error',
                            title: 'Notificaciones',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                    }
                })
            }
            
           


        }
        
        const onClickCancelLicencia = ()=>{
            onCancel();
            setEditing('');
            setCurrentEdit({});
        }

        const onClickNuevoEmpresa = ()=>{
            setStpNombre( '' );
            setEditing('');
            setCurrentEdit({});
        }

     
    //#region 

   

    return(
        <>
            <Card>
                
                <Grid container >
                    <Grid item xs={8} md={8} xl={5}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6" >Registrar</SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>

                <SoftBox pt={2} pb={3} px={3} component="form" role="form">
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} xl={6}>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Empresa
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>

                                <Select onChange={ onChangeEmpresa }
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name={"empresa"}
                                        options={ empresasList }
                                        value={ selectedItemEmpresa }
                                        menuPosition={'fixed'}
                                        styles={{
                                            container: (base) => ({
                                                ...base,
                                                fontSize:"14px",
                                                fontFamily:"Arial"
                                            }),
                                            option: (base) => ({
                                                ...base,
                                                fontSize:"12px",
                                                fontFamily:"Arial"
                                            })
                                        }}
                                />
                                

                                
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Licencia 
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <Select onChange={ onChangeLicencia }
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name={"licencia"}
                                        options={ LicenciasCollection }
                                        value={ selectedItemLicencia }
                                        menuPosition={'fixed'}
                                        styles={{
                                            container: (base) => ({
                                                ...base,
                                                fontSize:"14px",
                                                fontFamily:"Arial"
                                            }),
                                            option: (base) => ({
                                                ...base,
                                                fontSize:"12px",
                                                fontFamily:"Arial"
                                            })
                                        }}
                                />

                            </SoftBox>
                        </Grid>

                        <Grid item xs={4} md={3} xl={3}>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Centro de Costos STP 
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox> 
                                <SoftInput type="text" 
                                    placeholder="Cliente"
                                    value={idCliente}
                                    name="idCliente"
                                    id="idCliente" 
                                    inputProps={{ maxLength: 15 }}
                                    onChange={ e => setIdCliente(e.target.value) } 
                                    autoComplete="off"
                                    />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={4} md={3} xl={3}>
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Nombre Centro de Costos STP 
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox> 
                                <SoftInput type="text" 
                                    placeholder="Nombre STP" 
                                    value={stpNombre}
                                    inputProps={{ maxLength: 15 }}  
                                    name="stpNombre" 
                                    id="stpNombre"  
                                    error={ error.stpNombre }
                                    onChange={ e => setStpNombre(e.target.value) } 
                                    autoComplete="off"
                                    />
                            </SoftBox>
                        </Grid>
                        
                        
                        <Grid item xs={12} md={4} xl={3}>
                            <SoftBox pt={3}>
                                <SoftButton fullWidth  color="color8" onClick={ (event) => onClickCancelLicencia(event) }  >
                                    <IconBack /> Cancelar Captura
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={3} xl={2}>
                            <SoftBox pt={3}>
                                <SoftButton fullWidth   color="color5V"  onClick={ handleCrearCDC } >
                                    <IconSave /> Guardar
                                </SoftButton>
                            </SoftBox>
                        </Grid>

                    </Grid>
                </SoftBox>

            </Card>
        </>
    )
    
}

AddCentroDeCosto.propTypes = {
    cuenta_uuid: PropTypes.string,
    empresasList: PropTypes.any,
    seriesList: PropTypes.any,
    onAdd: PropTypes.func,
    onCancel: PropTypes.func,
    editItem: PropTypes.object,
    reset: PropTypes.any,
    idClienteCdc : PropTypes.any
  };

export default AddCentroDeCosto;