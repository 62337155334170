import React, {   useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Card from "@mui/material/Card";

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
import IconAdd from '@mui/icons-material/Add';

import SoftInput from "components/SoftInput";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2';
//
import EmpresasRequest from '../../../Repository/Empresas';
import ValidatorRFC from 'Repository/ValidatorRFC';
import {useAlert} from "../../../customHooks/useAlert";

function AddEmpresa({uuid, data ={},  onAdd, onCancel, reset }) {
    const { showAlert } = useAlert();
    const [errores, setErrores] = useState('' );
    const [NombreComercial, setNombreComercial] = useState( '' );
    const [Rs, setRs] = useState('');
    const [Rfc, setRfc] = useState( '' );
    const [direccion, setDirecion] = useState( '' );
    const [toEdit, setToEdit] = useState('');
    
    const validadorRfc = new ValidatorRFC();

    const empresax = new EmpresasRequest();

    //#region methods
    const saveData= (  )=>{

        setErrores('');
        if(!validate()){

            const params = {
                'rfc': Rfc,
                'razon_social': Rs,
                'nombreComercial' : NombreComercial,
                'cuenta_uuid' : uuid,
                'direccion': direccion,
                'uuid': toEdit
            }

            if(toEdit == '' || toEdit === undefined  ){
                empresax.store( JSON.stringify(params) ).then(response=>{
                    if(response.status == 'ok'){
                        showAlert('Excelente',response.response.text, 'success');
                        onAdd();
                        setRfc('');
                        setRs('');
                        setNombreComercial('');
                        setDirecion('');
                        setToEdit('');
                    }else{
                    setErrores(response.response.text);
                    }
                });
            }else{
                empresax.update( JSON.stringify(params) , toEdit ).then(response=>{
                    if(response.status == 'ok'){
                        showAlert('Excelente',response.response.text, 'success');
                        onAdd();
                    }else{
                    setErrores(response.response.text);
                    }
                });

            }

        }else{
            setErrores('Por favor rellene los campos necesarios para poder continuar');
        }    
    }

    function validate (){
        let hasError = false;
        let counter = 0;
        if(NombreComercial.length <= 3) { counter+=1; }
        if(Rs.length <= 5) { counter+=1 }
        if(Rfc.length < 12 || Rfc.length > 13) { counter+=1 }

        const validesRFC = validadorRfc.rfcValido(Rfc);
        if(!validesRFC){
            showAlert('Notificaciones',`RFC inválido.`, 'error');
            counter+= 1;
        }

        if(counter > 0){//XD no has capturado todo
            return (hasError = true);
        }
    
        return hasError;

    }

    //#endregion

    //#region  events

    useEffect(()=>{
        setNombreComercial(data.nombreComercial);
        setRfc(data.rfc);
        setRs(data.rs);
        setDirecion(data.direccion);
        setToEdit(data.uuid);
    },[data])

    useEffect(()=>{
        if(reset > 0){
            setNombreComercial('');
            setRfc('');
            setRs('');
            setDirecion('');
            setToEdit('');
        }
    },[reset]);

    const onClickAddEmpresa = (event)=>{
        saveData();
    }

    const onClickCancelEmpresa = (event)=>{
        onCancel();
    }

    const onClickNuevoEmpresa = ()=>{
        setNombreComercial('');
        setRfc('');
        setRs('');
        setDirecion('');
        setToEdit('');
    }
    //#endregion

    return(
        <>
            <SoftBox>
                <hr/>
                    <Grid container >
                        <Grid item xs={8} md={8} xl={12} pt={2}>
                            <SoftBox pl={5} display="flex" alignItems="center" lineHeight={0}>
                                <SoftTypography variant="button" fontSize="xxs" color="error" >
                                    { errores }
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <SoftBox  px={3} component="form" role="form" mb={2}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={4} xl={3}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Nombre Comercial
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox> 
                                    <SoftInput placeholder="Nombre comercial" 
                                        maxLength="10" 
                                        value={NombreComercial} onChange={ e => setNombreComercial(e.target.value) } autoComplete="off"
                                        
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={3}>
                                <SoftBox>
                                    <SoftBox mb={1} lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            RFC
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" placeholder="R.F.C." value={Rfc}
                                    maxLength="10"  
                                    onChange={ e => setRfc(e.target.value) } autoComplete="off"
                                    
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={6}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Razón Social
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput placeholder="Razón Social" value={Rs} 
                                    maxLength="10" 
                                    onChange={ e => setRs(e.target.value) } 
                                    autoComplete="off"
                                    
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={6}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Dirección
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <SoftInput placeholder="direccion" value={direccion}
                                               maxLength="10"
                                               onChange={ e => setDirecion(e.target.value) }
                                               autoComplete="off"
                                    />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={7}>
                                <SoftBox >
                                    
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <SoftBox >
                                    <SoftButton fullWidth   color="color8" onClick={ (event) => onClickCancelEmpresa(event) }  >
                                        <IconBack /> Cancelar captura
                                    </SoftButton>
                                </SoftBox>
                            </Grid>
                            {/*
                                <Grid item xs={12} md={4} xl={2}>
                                    <SoftBox >
                                        <SoftButton fullWidth variant="gradient"  color="warning" onClick={ (event) => onClickNuevoEmpresa(event) }  >
                                            <IconAdd /> Nuevo
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            */}
                            
                            <Grid item xs={12} md={3} xl={2}>
                                
                                <SoftBox >
                                    <SoftButton fullWidth   color="color5V" onClick={ (event) => onClickAddEmpresa(event) }  >
                                        <IconSave /> Guardar
                                    </SoftButton>
                                </SoftBox>
                            </Grid>

                        </Grid>
                    </SoftBox>
                <hr/>
            </SoftBox>
        </>
    )
    
}

AddEmpresa.defaultProps= {
    data:{
        'uuid' :'',
        'rs' : '',
        'nombreComercial':'',
        'rfc':''
    }
}

AddEmpresa.propTypes = {
    uuid: PropTypes.string,
    onAdd : PropTypes.func,
    data: PropTypes.object,
    onCancel: PropTypes.func,
    reset: PropTypes.any
  };

export default AddEmpresa;