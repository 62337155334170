
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useNumberFormat} from "../../../../customHooks/useNumberFormat";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

const columns = [
    { name: "Detalle", align: "left", width:"60%" },
    { name: "Retiros", align: "right" },
    { name: "Depositos", align: "right" },
    { name: "Saldo", align: "right" },
];
export const SumarioOperaciones = ({cantidadRetiros = 0, cantidadDepositos = 0}) => {
    const { numberFormat } = useNumberFormat();
    //#endregion
    return (
        <>
            <SoftBox  mb={1} pt={2}  >
                <Card color={"success"} >
                    <Grid container  pt={1}>
                        <Grid item xs={12} md={6} xl={6} textAlign={"center"} >  </Grid>
                        <Grid item xs={12} md={6} xl={6} textAlign={"center"} sx={{  bgcolor:colors.color14.main }} >
                            <SoftTypography variant={"h6"} > Sumario de Operaciones</SoftTypography>
                        </Grid>
                    </Grid>
                    <Grid container  >
                        <Grid item xs={12} md={6} xl={6} pl={1} >
                        </Grid>
                        <Grid item xs={12} md={1} xl={1}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} fontWeight={"bold"} > Retiros: </SoftTypography><br/>
                        </Grid>
                        <Grid item xs={12} md={1} xl={1}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} > { numberFormat(cantidadRetiros, 0) } </SoftTypography><br/>
                        </Grid>
                        <Grid item xs={12} md={1} xl={1}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} fontWeight={"bold"} > Depósitos: </SoftTypography><br/>
                        </Grid>
                        <Grid item xs={12} md={1} xl={1}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} > { numberFormat(cantidadDepositos, 0) } </SoftTypography><br/>
                        </Grid>
                        <Grid item xs={12} md={1} xl={1}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} fontWeight={"bold"} > Total: </SoftTypography><br/>
                        </Grid>
                        <Grid item xs={12} md={1} xl={1}  textAlign={"right"} pr={1} >
                            <SoftTypography variant={"caption"} > { numberFormat(cantidadRetiros + cantidadDepositos, 0) } </SoftTypography><br/>
                        </Grid>
                    </Grid>
                </Card>

            </SoftBox>
        </>

    )
}

// Typechecking props for the ListItem
SumarioOperaciones.propTypes = {
    cantidadRetiros: PropTypes.number,
    cantidadDepositos: PropTypes.number,
};

export default SumarioOperaciones;
