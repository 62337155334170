import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import SoftBadge from "components/SoftBadge/index.js";
//
import AutorizadoresRequest from '../../../Repository/Autorizador';
import Autorizador from '../../../Repository/Autorizador';

import Estatus from '../components/Estatus';
import ButtonDelete from './ButtonDelete';
import AddAutorizador from './AddAutorizador';
import IconClose from "@mui/icons-material/Close"

function Autorizadores({ cuenta_uuid , onCloseViewDetalle, onAddAutorizador, refreshDropDown, onCloseComponent }) {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    const autorizadorx = new AutorizadoresRequest();

    const handleRefresh = ()=> listar();

    //#region events

    const onSelectedCuentaCurrent = (event, autorizador_uuid)=>{
     
    }

    const handleOnDeleteItem = ( autorizador_uuid)=> {
       
        listar();
    }

    //Agregaron otro registro debemos actualiazar  listado
    const onAddAutorizadorHandle = ()=>{
        listar();
        onAddAutorizador();
    }

    const onRefreshEventX = ()=>{
        refreshDropDown();
    }

    //#endregion

    //#region methods
    function listar(textox = ''){
        autorizadorx.getAllByCuenta(cuenta_uuid).then(response=>{

            setColumns(
                [
                    { name: "Autorizador", align: "left" },
                    { name: "Celular", align: "center", title:"Razón Social" },
                    { name: "Correo", align: "center" },
                    { name: "Empresa", align: "center" },
                    { name: "OTP", align: "center" },
                    { name: "Estatus", align: "center" }
                ]
            );
            let item = [];
            (response.response.data).forEach(element => {
                
                let row = { 
                    Autorizador:   element.autorizador ,
                    Celular:element.cel,
                    Correo:element.email,
                    Empresa: <SoftTypography variant="caption">
                    { element.referencia }
                  </SoftTypography>,
                    OTP: <>
                    {
                        (element.otpSecret && element.otpLastUse) && (
                        <SoftBadge variant={  (element.otpSecret && element.otpLastUse) ? "gradient": "contained"  } badgeContent={  (element.otpSecret && element.otpLastUse) ? " otp ": "  "  } size="lg" container />
                        )
                    }  
                    </>,
                    Estatus: ( element.autorizador_uuid != element.owner_uuid && <Estatus key={element.uuid} item={element} 
                        objeto={ Autorizador } estado ={ (element.estatus_autorizador == null ? true: false) } refresh={ onRefreshEventX } />  ),
                        
                };
                item = [...item, row];
            });
          setRows(item);
        });
      }
    //#endregion

    
    useEffect(()=>{
        listar();
    },[]);

    return(
        <>
            <SoftBox pt={1} >
                <Card>
                    <Grid container >
                        <Grid item xs={8} md={4} xl={8}>
                            <SoftBox   alignItems="center" p={3}>
                                <SoftTypography > Autorizadores </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            
                        </Grid>
                        <Grid item xs={4} md={3} xl={1} pt={1} sx={{ textAlign:"right" }} >
                            <SoftButton    color="error" size="small" onClick={  onCloseComponent } title="Cerrar esta sección" >
                                <IconClose /> 
                            </SoftButton>
                        </Grid>
                    </Grid>

                    <AddAutorizador cuenta_uuid={ cuenta_uuid } onAdd = { () => onAddAutorizadorHandle() } />

                    <SoftBox
                        sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                        }}
                        >
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

Autorizadores.propTypes = {
    cuenta_uuid: PropTypes.string.isRequired,
    onCloseViewDetalle: PropTypes.func,
    onAddAutorizador : PropTypes.func,
    refreshDropDown: PropTypes.func,
    onCloseComponent: PropTypes.func
};

export default Autorizadores;