import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';
import IconAddTask from '@mui/icons-material/AddTask';
//
import CuentasRequest from '../../../Repository/Cuentas';


function ListadoCuentas({subtitle, onSelectedCuenta}) {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

   let cuentasx = new CuentasRequest();

    //#region events
    const handleRefresh = ()=> listar();

    const handleChangeBusqueda = ({target})=>{
        const value = target.value;
       
        setTxtBuscar(value);
        if(value.length > 3){
          listar(value);
        }else if(value == ''){
          listar('');
        }
        
    }

    const onSelectedCuentaCurrent = (event, uuid)=>{
        //event.preventDefault();
        onSelectedCuenta(uuid )
    }

    //#endregion

    //#region methods
    function listar(textox = ''){
        let params = {'txtbuscar':textox};
        cuentasx.listar(params).then(response=>{
          setColumns(
          [
            { name: "Identificador", align: "left" },
            { name: "Propietario", align: "center" },
            { name: "Creado", align: "center" },
          ]
          );
          let item = [];
          (response.response.data).forEach(element => {
            
            let row = { 
                Identificador:  <SoftTypography
                          component="a"
                          href="#"
                          variant="caption"
                          color="info"
                          fontWeight="medium"
                          onClick={ (event) => onSelectedCuentaCurrent(event, element.uuid) }
                        >
                          {  !element.alias ? element.propietario: element.alias } 
                        </SoftTypography> ,
                Propietario: (element.propietario ? element.propietario:'' ),
                Creado: element.created
                     
            };
            item = [...item, row];
          });
          setRows(item);
          
          
        });
      }
    //#endregion

    
    useEffect(()=>{
        listar();
    },[]);

    return(
        <>
            <SoftBox >
                <Card>
                    <Grid container pt={3}>
                        <Grid item xs={8} md={3} xl={2}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography >{subtitle}</SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item pl={1} xs={4} md={4} xl={2} pt={3}>
                            <SoftButton color="color5V" onClick={handleRefresh}  >
                                <IconRefresh fontSize="36px"  color="white" title="Actualizar" /> Refrescar
                            </SoftButton>
                        </Grid>
                        <Grid item pl={1} xs={4} md={1} xl={3}>

                        </Grid>
                        <Grid item xs={8} md={4} xl={5} pt={3} pr={3}>
                            <SoftBox >
                                <SoftInput placeholder="Buscar" type="text"
                                value={txtBuscar}
                                onChange={ (event)=> handleChangeBusqueda(event) } 
                                />
                            </SoftBox>
                        </Grid>
                        

                    </Grid>
                    <SoftBox
                        sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                        }}
                        >
                        <Table columns={columns} rows={rows} />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoCuentas.propTypes = {
    subtitle: PropTypes.string,
    onSelectedCuenta: PropTypes.func
};

export default ListadoCuentas;