
import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";

function DetalleCell2( { element } ) {
    const beneficiarioText = `${ element.beneficiario }`;
    return (
        <SoftBox>
            <Grid container columns={12}  >
                <Grid item xs={12} md={6} xl={4} sx={{ lineHeight:1 }}>
                    <SoftTypography variant={"caption"} fontWeight={"bold"}>Fecha:</SoftTypography>
                    <SoftTypography variant={"caption"}>{ element.fecha }</SoftTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={4} sx={{ lineHeight:1 }}>
                    <SoftTypography variant={"caption"} fontWeight={"bold"}>Hora:</SoftTypography>
                    <SoftTypography variant={"caption"}>{ element.hora }</SoftTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={4} sx={{ lineHeight:1 }}>
                    <SoftTypography variant={"caption"}  fontWeight={"bold"}>No. ref:</SoftTypography>
                    <SoftTypography variant={"caption"}> { element.referenciaNumerica } </SoftTypography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} sx={{ lineHeight:1 }} >
                    <SoftTypography variant={"caption"}  fontWeight={"bold"} >Concepto:</SoftTypography>
                    <SoftTypography variant={"caption"} >{ element.conceptoPago }</SoftTypography>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} md={12} xl={12} sx={{ lineHeight:1 }} >
                    <SoftTypography variant={"caption"}  fontWeight={"bold"}>{ element.tipo == 'RETIRO'? 'Beneficiario':'Ordenante' }:</SoftTypography>
                    <SoftTypography variant={"caption"}> { element.tipo == 'RETIRO' ? element.beneficiario : element.ordenante    }</SoftTypography>
                    {
                        element.estado == 'DEVUELTO' && <SoftTypography sx={{ color:"#E40C0C",fontSize:12, fontWeight:"bold",  }} title="Orden de dispersión devuelta" > DEVUELTO</SoftTypography>
                    }
                </Grid>

            </Grid>

        </SoftBox>
    );
}

// Typechecking props for the ListItem
DetalleCell2.propTypes = {
    element: PropTypes.object.isRequired
};

export default DetalleCell2;